import axios from 'axios';
import CryptoJS from 'crypto-js';

function encrypt(ptext ,pass){

    return CryptoJS.AES.encrypt(ptext, pass).toString();

}

function decrypt(etext, pass){

    return CryptoJS.AES.decrypt(etext, pass).toString(CryptoJS.enc.Utf8);
}


async function getprice(){

    const url = 'https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=aryacoin%2Cbitcoin%2Cdash&order=id_asc&per_page=100&page=1&sparkline=true&price_change_percentage=24h';
    const resp = await axios.get(url);
    const data = resp.data;
    return([data[0],data[1],data[2]])


}



async function get_address_info(currency_id){

    if(currency_id === 'aya'){

        const url = 'https://ayablockexplorer.com/api/v1/address/' + localStorage.getItem("aya_address") + '?details=txs';
        const resp = await axios.get(url);
        const data = resp.data;
        return data;
    }
    else if(currency_id === 'btc'){

        const url = 'https://bitcoinblockexplorers.com//api/v1/address/' + localStorage.getItem("btc_address") + '?details=txs';
        const resp = await axios.get(url);
        const data = resp.data;
        return data;
    }
    else if(currency_id === 'dash'){

        const url = 'https://dashblockexplorer.com//api/v1/address/' + localStorage.getItem("dash_address") + '?details=txs';
        const resp = await axios.get(url);
        const data = resp.data;
        return data;
    }
    else{
        return null;
    }
    
}

async function getchart(currency,vs_currency='usd',from_time=0,to_time=0){
    
    if(from_time === 0){
        to_time = new Date().getTime() ;
        from_time = new Date(to_time - 2592000000).getTime()  ; 
    }
    
    const url = 'https://api.coingecko.com/api/v3/coins/'+currency+'/market_chart/range?vs_currency='+vs_currency+'&from='+from_time / 1000 +'&to='+ to_time / 1000;
    const resp = await axios.get(url);
    const data = resp.data.prices;
    let fn_p = false;
    const map_prices = data.map(p_data =>{
        p_data[1] = p_data[1].toFixed(4);
        if (p_data[1] === 0){
            fn_p = true;
        }
        if(fn_p === true){
            return;
        }
        return p_data;
    });
    return map_prices;
}

function gen_mnemonic(){

    var Mnemonic = require('ayacore-mnemonic');
    var code = new Mnemonic(Mnemonic.Words.ENGLISH);
    //var xpriv = code.toHDPrivateKey();
    console.log('Mnemonic:' + code.toString());
    return code.toString();
}

function verify_mnemonic(mnemonic_code){
    var Mnemonic = require('ayacore-mnemonic');
    var valid = Mnemonic.isValid(mnemonic_code);
    return valid;
}

export {getprice,getchart,gen_mnemonic,verify_mnemonic,get_address_info,encrypt,decrypt};