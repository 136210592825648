import axios from 'axios';
import {HDPrivateKey,PrivateKey, Transaction, Mnemonic} from 'dashcore-lib';

function gen_dashkey(mnemonic_code){
    
    //var Mnemonic = require('dashcore-mnemonic');
    var code = new Mnemonic(mnemonic_code);
    var xpriv = code.toHDPrivateKey();
    var pvtkey = new HDPrivateKey(xpriv).privateKey.toString();
    var privkey =  new PrivateKey.fromWIF(pvtkey);
    var dash_publicKey = privkey.toPublicKey();
    var dash_address = dash_publicKey.toAddress();
    localStorage.setItem('dash_address', dash_address.toString());
    //localStorage.setItem('dash_key', privkey);
    return privkey;
    
}

async function getUnspentOutputs (address) {
    const url = 'https://dashblockexplorer.com/api/v2/utxo/' + address;
    const resp = await axios.get(url);
    const data = resp.data;
    return data;
}

const convertUTXO = (utxo) => (
    {
      txId: utxo.txid,
      outputIndex: utxo.vout,
      address: utxo.address,
      script: utxo.script,
      satoshis: Number(utxo.value),
    }
)


async function getUTXOs (address) {

    let utxos = await getUnspentOutputs(address);
    
    //check UTXO's
    //utxos = utxos.filter(utxo => utxo.confirmations > 0)
    utxos = utxos.filter(utxo => Number(utxo.value) > 1000)
    if (utxos.length === 0){
        console.log('No Valid Output');
    }
    else{
        console.log("getUTXOs" + JSON.stringify(utxos));
        //add utxo   script in json array
        for(var utxo_data in utxos){
            const url = 'https://dashblockexplorer.com/api/v2/tx/' + utxos[utxo_data].txid;
            const resp = await axios.get(url);
            const data = resp.data;
            utxos[utxo_data].script = data.vout[utxos[utxo_data].vout].hex;
            utxos[utxo_data].address = address;
        }
        console.log("getUTXOs2" + JSON.stringify(utxos));
        utxos = utxos.map(convertUTXO);
        console.log("getUTXOs3" + JSON.stringify(utxos));
        return utxos;

    }
    

}

async function checkUTXOs( utxos, address) {

    //utxos = utxos.filter(utxo => utxo.confirmations > 0)
    utxos = utxos.filter(utxo => Number(utxo.value) > 1)
    if (utxos.length === 0) throw Error(`No spendable outputs were found, please fund the address or wait for transactions to confirm - address: ${address}`)
}

async function create_dash_tx (from_address ,from_key , to_address , amount , dash_data = "false", fees= 6000){
    
    try{

        if(dash_data === "false"){
            amount = parseInt(amount * 100000000);
            if(amount < fees){
                return ([false,'Amount too low']);
            }
        }
        //send amounts in satoshis
        //amount = parseInt(amount * 100000000);
        let utxos = await getUTXOs(from_address);
        console.log("create_dash_tx" + JSON.stringify(utxos));
        let spendable_balance = 0 ;
        let utxo_use = 0;
        let use_utxos = []; 
        //caclulate whole utxo output
        for(var utxo_data in utxos){
            spendable_balance = spendable_balance + utxos[utxo_data].satoshis;
            use_utxos.push(utxos[utxo_data]);
            if (spendable_balance > (amount+fees)){
                //get utxo's required for amount
                utxo_use = utxo_data;
                console.log("Found breakpoint:" + JSON.stringify(utxo_use));
                break; 
            }
        }
        console.log("Found breakpoint Array:" + JSON.stringify(use_utxos));
        console.log("Sepndable balance:" + spendable_balance);
        console.log("Wanted balance:" + amount);
        if((amount+fees) <= spendable_balance){
            
            console.log("utxos:", use_utxos);
            if(dash_data === "false"){
                const tx = new Transaction()
                .from(utxos)
                .to(to_address, amount)
                .change(from_address)
                .fee(fees)
                .sign(new PrivateKey(from_key));

                const txHex = tx.serialize();
                console.log("Spendabe tx:", txHex);

                const url = 'https://dashblockexplorer.com/api/v2/sendtx/' + txHex;
                const resp = await axios.get(url);
                const data = resp.data;
                console.log(data);

                localStorage.setItem('f_update','true');
                return ([true,data.result]);
            }
            else{   
                /*const tx = new dashcore.Transaction()
                .from(utxos);

                tx.addOutput(new dashcore.Transaction.Output ({
                    script: Script().add('OP_RETURN ' + dash_data) ,
                    satoshis: 0
                }));
                tx
                .to(to_address, amount)
                .change(from_address)
                .fee(0)
                .sign(from_key);


                const txHex = tx.serialize();
                console.log("tx:", txHex);*/
                
            }
            
            
        }
        else{
            //not enough spendable balance
            console.log("Not enough balance");
            return [false,"Insufficient Dash(DASH) Balance"];
        }
    }
    catch(ee){

        console.log(ee);
        return [false,"Please try again later"];
        
    }
    


    

}

export {gen_dashkey,create_dash_tx};