import React, { useState } from "react";
import { connect } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { Button, FormControl, Modal, FloatingLabel } from 'react-bootstrap'
import { aya_omni_managed_grant } from '../../../helpers/ayautils';
import NotificationModal from "../../Modals/Notification/Notification";
import { decrypt } from '../../../helpers/cryptoutils';


const IssueNFTModule = (props) => {

    const { asset_data } = props;
    const [token_amount, set_token_amount] = useState(0);
    const [token_data, set_token_data] = useState(null);

    const [show, setShow] = useState([false,'static']);
    const [show_status, setShow_status] = useState(['Recovering Keys','Recovering Keys']);
    const handleClose = () => setShow([false,'static']);
    const handleShow = () => setShow([true,'static']);
    
    const [wpass, setwpass] = useState(sessionStorage.getItem("sess_pass"));
    const [showp, setshowp] = useState(false);

    const send_tx = async() =>{
        
        setshowp(false);
        if(wpass !== null ){
            setShow_status(['Creating and Sending Transaction','Creating and Sending Transaction']);
            handleShow();
            try{
                
                const tx_stats = await aya_omni_managed_grant(localStorage.getItem("aya_address"),decrypt(localStorage.getItem("aya_key"),wpass),localStorage.getItem("aya_address"),token_amount,asset_data.id, token_data);
                if(tx_stats[0] !== true){
                    setShow_status(['Error','Error: ' + tx_stats[1]]);
                }
                else{
                    const stat = tx_stats[1] + ';' + token_amount;
                    setShow_status(['Success',stat]);
                }
            }
            catch(e){
                setShow_status(['Error','API Error Occured Please try again later']);
                setwpass(null);
            }
            setShow([true,true]);
            localStorage.setItem('f_update','true');
        }
        else{
            setshowp(true);
        }
        
        
    };
    
    if(asset_data.propertyinfo.issuer === localStorage.getItem("aya_address") && asset_data.propertyinfo.propertytype === "non-fungible"){
        
        return (
            <>
                                <div className="zl_all_page_comman_content">
                                    <div className="zl_send_currency_input_content">
                                        <FloatingLabel style={{ color: "#C0C3CC" }} controlId="tok_amt" label="Issue Amount">
                                            <FormControl as="input" htmlSize={200} className="zl_send_currency_input" type="number" placeholder="Issue Amount" onChange={(event) => {set_token_amount(event.target.value)}} />
                                        </FloatingLabel>
                                    </div>
                                    <br/>
                                    <div className="zl_send_currency_input_content">
                                        <FloatingLabel style={{ color: "#C0C3CC" }} controlId="tok_data" label="NFT Data">
                                            <FormControl as="input" htmlSize={200} className="zl_send_currency_input" type="text" placeholder="NFT Data" onChange={(event) => {set_token_data(event.target.value)}} />
                                        </FloatingLabel>
                                    </div>
                                    <br />
                                    <div className="zl_send_currency_btn_text">
                                        <Button className="zl_send_currency_btn" id="send_butx" onClick={send_tx}>Issue</Button>
                                        <div className="zl_send_currency_text">
                                            <p>Network Fee<span>0.000040001 AYA</span></p>
                                        </div>
                                    </div>
                                    <NotificationModal show_status={show_status} show={show} type={'omni_issue'}/>  
                                    <Modal style={{'textAlign':'center'}} centered show={showp} onHide={() => setshowp(false)} backdrop={true} keyboard={false} >
                                        <Modal.Header closeButton>
                                        <Modal.Title>Enter Password</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div className="zl_login_input_content position-relative">
                                                <input type="password" className="zl_login_input" id={`pass_input`} onChange={(event) => {setwpass(event.target.value)}} placeholder='********'  />
                                            </div>
                                            <br />
                                            <div className="zl_login_btn">
                                                <Button id="pass_btn" onClick={ () => {send_tx();} } className="zl_send_currency_btn btn btn-primary"> {'Send Transaction' }</Button>
                                            </div>
                                        </Modal.Body>
                                    </Modal>               
                                </div>
            </>
        );

    }
    else{
        return (<> </>);
    }
    
    

}

export default connect(null, null)(IssueNFTModule);
