import React, { useState } from "react";
import { connect } from "react-redux";
import HeadingModule from '../components/Layout/HeadingComponent/Heading';
import { FormControl, Button, Modal, FloatingLabel, FormSelect , Spinner} from 'react-bootstrap'
import axios from 'axios';
import { ethers } from 'ethers'
import Web3Modal from 'web3modal'
import WalletConnectProvider from '@walletconnect/web3-provider'

const BridgeModule = () =>{

    const [network_fee, set_network_fee] = useState(100);
    const [to_network, set_to_network] = useState('bsc');
    const [to_address, set_to_addresss] = useState('');
    const [to_amount, set_to_amount] = useState('0');
    const [to_step, set_to_step] = useState(0);
    const [show, setShow] = useState(false);
    const [spin, setSpin] = useState(false);
    const [sback, setsback] = useState('static');
    const [tx_id, set_tx_id] = useState('');
    const [connection, setConnection] = useState(false)
    const [show_status, setShow_status] = useState('Generating Payment Address');
    const [show_status_, setShow_status_] = useState('Generating Payment Address');
    const handleClose = () => setShow(false);
    const [button_text, set_button_text] = useState('Connect Wallet');

    const send_tx = async () => {

        setShow(true);

        const url = 'https://ayapay.aryacoin.io/apiv1?cmd=get_bridge_payment&type=' + to_network + '&address='+to_address;
        const resp = await axios.get(url);
        const data = resp.data;
        setSpin(true);
        setShow_status('Payment Address');
        setShow_status_('Send AYA to the address: ' + data['result']['address'] + ' , a fee of ' + network_fee + 'AYA will be deducted and the transaction would be credited in the target network after 50 confirmations automatically (Estimated time: 30mins) .');

    };

    const check_tx = async() => {
        if(to_network === 'bsc'){
            await send_tx();
        }
        else{
            await set_tx();
        }
    }

    const set_tx = async () => {
        if(to_step == 0){
            set_button_text('Awaiting Connection');
            const web3Modal = await getWeb3Modal();
            const connection = await web3Modal.connect();
            setConnection(connection);
            set_button_text('Next');
            set_to_step(1);
        }
        else if(to_step === 1){
            set_button_text('Awaiting Approval');
            const provider = new ethers.providers.Web3Provider(connection);
            const signer = await provider.getSigner();
            const Erc20Abi = [
                "function transfer(address to, uint amount)",
                ];
            const ayaContract = new ethers.Contract('0x86e5775F5c46304939c40959812bC220dD1c0333', Erc20Abi, provider);
            const ayaWithSigner = await ayaContract.connect(signer);
            const tx = await ayaWithSigner.transfer("0xbd83f5b0B80B9bACf72CDa06fFCf8da54Ca46159", parseInt(to_amount) * (10**8));
            console.log(tx.hash);
            set_tx_id(tx.hash);

            set_button_text('Awaiting Confirmation..');
            const rec = await tx.wait();
            //const receipt =  await provider.getTransactionReceipt(tx.hash);
            //console.log(`Receipt ${receipt}`);
            set_button_text('Complete Transaction');
            set_to_step(2);

        }
        else{
            setShow_status('Please Wait..');
            set_to_step(3);
            const provider = new ethers.providers.Web3Provider(connection);
            const signer = await provider.getSigner();
            const u_add = await signer.getAddress();
            const sig_msg =to_amount + ';' +to_address+ ';'  +tx_id + ';' +Date.now() + ';' + u_add ; 
            const signature = await signer.signMessage(sig_msg);
            const url = 'https://ayapay.aryacoin.io/apiv1?cmd=get_bridge_payment_aya&type=' + to_network + '&message='+sig_msg + '&sign=' + signature + '&amount='+to_amount;
            const resp = await axios.get(url);
            const data = resp.data;
            console.log(`Signature ${signature}`);
            setShow(true);
            setSpin(true);
            setShow_status('Payment Complete');
            setShow_status_('The payment on AYA Mainnet will be processed after the transaction receives 50 confirmations.');
            set_to_step(0);
        }
        
      

    };

    async function getWeb3Modal() {
      const web3Modal = new Web3Modal({
        network: 'mainnet',
        cacheProvider: false,
        providerOptions: {
          walletconnect: {
            package: WalletConnectProvider,
            options: {
              rpc: {
                  56: "https://bsc-dataseed1.defibit.io/"
              }
            },
          },
        },
      })
      return web3Modal
    }

    localStorage.setItem('themColor','zl_page_dark_mode');
    return (
        <>
            <section className="zl_wallets_page">
                <HeadingModule name={'Aryacoin Cross-Chain'} heading_description={'Aryacoin Cross-Chain Transfer'} />
                <div className="zl_add_currency_content">
                    <h3 className="zl_bottom_content_heading">Cross-Chain Transfer Aryacoin from mainnet to BSC and ETH networks</h3>
                        
                </div>
                <div className="zl_send_recive_content_row">
                    <div className="zl_send_recive_content_column">
                        <div className="zl_send_recive_inner_content">
                            <div className="zl_send_currency_input_content">
                                <FloatingLabel style={{ color: "#C0C3CC" }} controlId="tok_atype" label="From Network">
                                    <FormSelect disabled={to_step > 0 ? true : false} style={{width: '105%', height:'105%'}} defaultValue={to_network === 'bsc' ? 'aya' : 'bsc'} value = {to_network === 'bsc' ? 'aya' : 'bsc'} className="zl_send_currency_input" >
                                        <option value="aya">Aryacoin Mainnet</option>
                                        <option value="bsc">Binance Smart Chain</option>
                                    </FormSelect>
                                </FloatingLabel>
                                <br/>
                            </div>

                            <div className="zl_send_currency_input_content">
                                <FloatingLabel style={{ color: "#C0C3CC" }} controlId="tok_btype" label="To Network">
                                    <FormSelect disabled={to_step > 0 ? true : false} style={{width: '105%', height:'105%'}} className="zl_send_currency_input"  defaultValue={to_network} onChange={(event) => {set_to_network(event.target.value); if(event.target.value === 'bsc') {set_network_fee(100); } else {set_network_fee(2000); }}}>
                                        <option value="bsc">Binance Smart Chain</option>
                                        <option value="aya">Aryacoin Mainnet</option>
                                    </FormSelect>
                                </FloatingLabel>
                                <br/>
                            </div>
                            <div className="zl_send_currency_input_content">
                                    <FloatingLabel style={{ color: "#C0C3CC" }} controlId="dest_amt" label="Total Amount">
                                        <FormControl disabled={to_step > 0 ? true : false} as="input" htmlSize={200} className="zl_send_currency_input" type="text" placeholder="AYA Amount" onChange={(event) => {set_to_amount(event.target.value)}} />
                                    </FloatingLabel>
                                    <br/>
                            </div>
                            <div className="zl_send_currency_input_content">
                                    <FloatingLabel style={{ color: "#C0C3CC" }} controlId="dest_addy" label="Destination Address">
                                        <FormControl disabled={to_step > 0 ? true : false} as="input" htmlSize={200} className="zl_send_currency_input" type="text" placeholder="Destination Address" onChange={(event) => {set_to_addresss(event.target.value)}} />
                                    </FloatingLabel>
                                    <br/>
                            </div>
                            <br/>
                            <div className="zl_send_currency_btn_text">
                                <Button disabled={to_step > 2 ? true : false} className="zl_send_currency_btn" id="send_but" onClick={check_tx}>{to_network === 'bsc' ? 'Submit' : button_text}</Button>
                                <div className="zl_send_currency_text">
                                    <p>Network Fee<span>{to_network === 'bsc' ? network_fee : '0'} AYA</span></p>
                                    <p>Note: The coins will be sent on the target network once the deposit has reached 50 confirmations (Estimated Time: 30mins) </p>
                                </div>
                            </div>
                            <Modal show={show} onHide={handleClose} backdrop={sback} keyboard={false} >
                                <Modal.Header>
                                <Modal.Title>{show_status}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                {show_status_}
                                <Spinner hidden={spin} animation="border" />
                                </Modal.Body>
                            </Modal>
                                                                
                        </div>
                    </div>
                </div>
            </section>
            
        </>
    );

}

export default connect(null, null)(BridgeModule);
