import React from "react";
import { connect } from "react-redux";
import { Table } from 'react-bootstrap'
import { assets_info } from "../../../helpers/assets_info";

const AssetTransactionListComponent = (props) => {

    const { id } = props;

    const filter_tx = JSON.parse(localStorage.getItem('aya_omni_data')).filter((itemx) => { return Number(itemx.propertyid) === Number(id) });

    const transactionList = [];

    console.log('Asset detials:' + id);
    console.log(filter_tx.length);
    if(filter_tx.length === 0){
        return (
            <>
                <div className="overflow-auto">
                    <Table className="zl_transaction_list_table">
                        <thead>
                            <tr>
                                <th className="zl_transaction_list_table_heading">type</th>
                                <th className="zl_transaction_list_table_heading">name</th>
                                <th className="zl_transaction_list_table_heading">transaction id</th>
                                <th className="zl_transaction_list_table_heading">value</th>
                                <th className="zl_transaction_list_table_heading">status</th>
                                <th className="zl_transaction_list_table_heading">date</th>
                            </tr>
                        </thead>
                        <tbody>
                            
                        </tbody>
                    </Table>
                </div>
            </>
        );
    }
    else{

        console.log('RUNNING ELSE Asset detials:' + id);
        filter_tx.map((item) => {

            if(item.referenceaddress){
                //minus
                if(item.referenceaddress !== localStorage.getItem('aya_address')){
                    transactionList.push(
    
                        {
                            id: transactionList.length,
                            transactionType: item.type,
                            transactionName: item.propertyname,
                            transactionId: item.txid,
                            transactionValue: '-'+ Number(item.amount).toFixed(4),
                            transactionValueUpDown: 'zl_transaction_minas',
                            transactionStatus: 'Completed',
                            transactionStatusUpDown: 'zl_transaction_completed',
                            transactionDate: new Date(item.blocktime * 1000).toString()
                        }
            
            
                    );
                }
                else{
                    transactionList.push(
    
                        {
                            id: transactionList.length,
                            transactionType: item.type,
                            transactionName: item.propertyname,
                            transactionId: item.txid,
                            transactionValue: '+'+ Number(item.amount).toFixed(4),
                            transactionValueUpDown: 'zl_transaction_pluse',
                            transactionStatus: 'Completed',
                            transactionStatusUpDown: 'zl_transaction_completed',
                            transactionDate: new Date(item.blocktime * 1000).toString()
                        }
            
            
                    );
                }
            }
            else{
                //Addition
                transactionList.push(
    
                    {
                        id: transactionList.length,
                        transactionType: item.type,
                        transactionName: item.propertyname,
                        transactionId: item.txid,
                        transactionValue: '+'+ Number(item.amount).toFixed(4),
                        transactionValueUpDown: 'zl_transaction_pluse',
                        transactionStatus: 'Completed',
                        transactionStatusUpDown: 'zl_transaction_completed',
                        transactionDate: new Date(item.blocktime * 1000).toString()
                    }
        
        
                );
            }

        });

        

        return (
            <>
                <div className="overflow-auto">
                    <Table className="zl_transaction_list_table">
                        <thead>
                            <tr>
                                <th className="zl_transaction_list_table_heading">name</th>
                                <th className="zl_transaction_list_table_heading">transaction id</th>
                                <th className="zl_transaction_list_table_heading">value</th>
                                <th className="zl_transaction_list_table_heading">status</th>
                                <th className="zl_transaction_list_table_heading">date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {transactionList.map((transactionListData, i) => (
                                <tr key={transactionListData.id}>
                                    <td className="zl_transaction_list_name">{transactionListData.transactionName}</td>
                                    <td className="zl_transaction_list_id"> <a href={"https://omniexplorer.aryacoin.io/tx/" + transactionListData.transactionId } target="_blank">{transactionListData.transactionId}</a></td>
                                    <td className={`${transactionListData.transactionValueUpDown} zl_transaction_list_value`}>{transactionListData.transactionValue}</td>
                                    <td className={`${transactionListData.transactionStatusUpDown} zl_transaction_list_status`}>{transactionListData.transactionStatus}</td>
                                    <td className="zl_transaction_list_date">{transactionListData.transactionDate}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            </>
        );
    }


}

export default connect(null, null)(AssetTransactionListComponent);
