
export const contactEmail = "support@xerxeswallet.com";

export const routes = {
    // homepage: "/",
    // faq: "/faq/",
    // giveaway: "/giveaway/",
    // support: "/support/",
    // company: "/company/",
    // assets: "/assets",
    
    welcomepage: "/",
    portfoliopage: "/portfolio",
    dashboardpage: "/dashboard",
    addcurrencypage: "/addcurrency",
    walletspage: "/wallets",
    historypage: "/history",
    securebackuppage: "/securebackup",
    settingspage: "/settings",
    restorewalletpage: "/restorewallet",
    currencypage: "/currency",
    assetspage: "/asset",
    account: "/dashboard/account",
    createassetpage: "/createasset",
    issueassetpage: "/issueasset",

    loginpage: "/login",
    //login2fa: "/account/login/2fa/",
    signup: "/signup",
    forgotten: "/forgotten",
    logout: "/logout",
    bridgepage: "/bridge",
    bridgereprocesspage: "/bridgereprocess",
    ayadaopage: "/ayadao",
};