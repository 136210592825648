import React , { useState } from "react";
import { connect } from "react-redux";
import HeadingModule from '../components/Layout/HeadingComponent/Heading';
import { Sparklines, SparklinesLine, SparklinesSpots } from 'react-sparklines';
import { Link } from 'react-router-dom';
import { Tab, Nav } from 'react-bootstrap';
import WalletDetails from '../components/Layout/WalletComponent/main';
import { getprice, get_address_info } from '../helpers/cryptoutils'
import { cuurency } from "../helpers/currency_info";
import { get_omni_data, get_omni_balance } from '../helpers/ayautils';


const PortfolioModule = () => {

    const [coin_data, set_coin_data] = useState(null);
    
    if(localStorage.getItem('aya_data') !== null && coin_data ===null){
        localStorage.setItem('last_update', new Date().getTime());
        set_coin_data([{data:JSON.parse(localStorage.getItem('aya_data')),price:JSON.parse(localStorage.getItem('aya_price'))},{data:JSON.parse(localStorage.getItem('btc_data')),price:JSON.parse(localStorage.getItem('btc_price'))},{data:JSON.parse(localStorage.getItem('dash_data')),price:JSON.parse(localStorage.getItem('dash_price'))}]);        
    }


    const get_coin_data = async() => { 

        //console.log('Running Coin_get_data');

        if(new Date().getTime() > Number(localStorage.getItem('last_update'))+60000  || localStorage.getItem('f_update') === 'true'){
            //Update only if data is 30sec older
            localStorage.setItem('last_update', new Date().getTime());
            localStorage.setItem('f_update','false');

            const price_data_ = await getprice();
            console.log(price_data_);

            const c_data = [];
            await Promise.all(cuurency.map( async(item) => {
                c_data[item.id] = await get_address_info(item.name.toLowerCase());
                localStorage.setItem(item.name.toLowerCase() + '_data', JSON.stringify(c_data[item.id])) ;
                localStorage.setItem(item.name.toLowerCase() + '_price', JSON.stringify(price_data_[item.id]));
            }));

            console.log('CointData:' + c_data);
            console.log('PRiceData:' + price_data_);

            const _bal = await get_omni_balance(localStorage.getItem("aya_address"));
            const _data = await get_omni_data(localStorage.getItem("aya_address"));
            
            localStorage.setItem('aya_omni_balance', JSON.stringify(_bal)); 
            localStorage.setItem('aya_omni_data', JSON.stringify(_data)); 

            set_coin_data([{data:JSON.parse(localStorage.getItem('aya_data')),price:JSON.parse(localStorage.getItem('aya_price'))},{data:JSON.parse(localStorage.getItem('btc_data')),price:JSON.parse(localStorage.getItem('btc_price'))},{data:JSON.parse(localStorage.getItem('dash_data')),price:JSON.parse(localStorage.getItem('dash_price'))}]); 
            
        }
        
    }

    //Create interval
    //const interval = setInterval(get_coin_data(), 60000);
    const update_interval = setInterval(get_coin_data, 10000);


    

    const ntive_icon = <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.60609 2.39391L2.69695 1.48477C2.36222 1.15004 1.81951 1.15004 1.48477 1.48477C1.15004 1.81951 1.15004 2.36222 1.48477 2.69695L2.39391 3.60609L0 6H6V0L3.60609 2.39391Z" fill="#E3507A" /></svg>;
    const ptive_icon = <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.60609 3.60609L2.69695 4.51523C2.36222 4.84996 1.81951 4.84996 1.48477 4.51523C1.15004 4.18049 1.15004 3.63778 1.48477 3.30305L2.39391 2.39391L0 0H6V6L3.60609 3.60609Z" fill="#50E2C2" /></svg>;
    if(coin_data !== null){
        return (
            <>
                <section className="zl_wallets_page">
                    <HeadingModule name={'Wallets'} heading_description={'View your wallets and balances.'} />
                    <Tab.Container id="left-tabs-example" defaultActiveKey="tab0">
                        <div className="zl_add_currency_content">
                            <h3 className="zl_bottom_content_heading">crypto currencies</h3>
                            <Nav className="zl_add_currency_row row">
                                <div className="zl_currency_column_sub_row">
                                    {cuurency.map((item) => (

                                        <Nav.Item className="zl_add_currency_column col" key={'cur_col'+ item.id}>
                                            <Nav.Link eventKey={`tab${item.id}`} className={"zl_add_currency_inner_content zl_add_"+item.full_name.toLowerCase()+ "_currency"}>
                                                <div className="zl_add_currency_icon_chart">
                                                    <div style={{width: '70%'}}>
                                                    <img  width="28" height="28" src={item.icon} alt="logo"/>
                                                    </div>
                                                    <Sparklines data={[coin_data[item.id].price.sparkline_in_7d.price[24],coin_data[item.id].price.sparkline_in_7d.price[48],coin_data[item.id].price.sparkline_in_7d.price[72],coin_data[item.id].price.sparkline_in_7d.price[96],coin_data[item.id].price.sparkline_in_7d.price[120],coin_data[item.id].price.sparkline_in_7d.price[144],coin_data[item.id].price.sparkline_in_7d.price[166]]} margin={6} className="zl_add_currency_mini_chart">
                                                        <SparklinesLine style={{ strokeWidth: 10, stroke: item.stroke, fill: "none", curve: "smooth" }} />
                                                        <SparklinesSpots size={4}
                                                            style={{ stroke: item.stroke, strokeWidth: 3, fill: "white" }} />
                                                    </Sparklines>
                                                </div>
                                                <div className="zl_add_currency_price">
                                                    <div className="zl_add_currency_left_price">
                                                        <h3>{item.name}</h3>
                                                        <p>{coin_data[item.id].data.balance}</p>
                                                    </div>
                                                    <div className="zl_add_currency_right_price">
                                                        <span className={coin_data[item.id].price.price_change_percentage_24h > 0 ? "" : "zl_add_currency_down_price"}>
                                                            {coin_data[item.id].price.price_change_percentage_24h > 0 ? ptive_icon : ntive_icon}
                                                            {(coin_data[item.id].price.price_change_percentage_24h).toFixed(4)}%
                                                        </span>
                                                        <p>{ '$' +  (coin_data[item.id].data.balance * coin_data[item.id].price.current_price).toFixed(4)}</p>
                                                    </div>
                                                </div>
                                            </Nav.Link>
                                        </Nav.Item>   


                                    ))}
                                </div>
                               
                            </Nav>
                        </div>
                        <Tab.Content>
                            {cuurency.map((item) => (
                            <Tab.Pane eventKey={`tab${item.id}`}  key={item.id}>
                                <WalletDetails id={item.id} coin_data={coin_data} />
                            </Tab.Pane>
                            ))}
                            {/* <Tab.Pane eventKey="tab1">
                                <MainComponent />
                            </Tab.Pane>
                            <Tab.Pane eventKey="tab2">
                                <AryacoinComponent />
                            </Tab.Pane>
                            <Tab.Pane eventKey="tab3">
                                <DashComponent />
                            </Tab.Pane> */}
                        </Tab.Content>
                    </Tab.Container>
                </section>
            </>
        );
    }
    else{
        get_coin_data();
        return (
            <>
                <section className="zl_wallets_page">
                    <HeadingModule name={'Wallets'} heading_description={'View your wallets and balances.'} />
                    <Tab.Container id="left-tabs-example" defaultActiveKey="tab1">
                        <div className="zl_add_currency_content">
                            <h3 className="zl_bottom_content_heading">crypto currencies</h3>
                            <Nav className="zl_add_currency_row row">
                                <div className="zl_currency_column_sub_row">
                                {cuurency.map((item) => (

                                    <Nav.Item className="zl_add_currency_column col" key={'cur_col'+ item.id}>
                                        <Nav.Link eventKey={`tab${item.id}`} className={"zl_add_currency_inner_content zl_add_"+item.full_name.toLowerCase()+ "_currency"}>
                                            <div className="zl_add_currency_icon_chart">
                                                <div style={{width: '70%'}}>
                                                <img  width="28" height="28" src={item.icon} alt="logo"/>
                                                </div>
                                                <Sparklines data={[]} margin={6} className="zl_add_currency_mini_chart">
                                                    <SparklinesLine style={{ strokeWidth: 10, stroke: item.stroke, fill: "none", curve: "smooth" }} />
                                                    <SparklinesSpots size={4}
                                                        style={{ stroke: item.stroke, strokeWidth: 3, fill: "white" }} />
                                                </Sparklines>
                                            </div>
                                            <div className="zl_add_currency_price">
                                                <div className="zl_add_currency_left_price">
                                                    <h3>{item.name}</h3>
                                                    <p>0</p>
                                                </div>
                                                <div className="zl_add_currency_right_price">
                                                    <span className="zl_add_currency_down_price">
                                                        <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M3.60609 2.39391L2.69695 1.48477C2.36222 1.15004 1.81951 1.15004 1.48477 1.48477C1.15004 1.81951 1.15004 2.36222 1.48477 2.69695L2.39391 3.60609L0 6H6V0L3.60609 2.39391Z" fill="#E3507A" />
                                                        </svg>
                                                        -5.23%
                                                    </span>
                                                    <p>0</p>
                                                </div>
                                            </div>
                                        </Nav.Link>
                                    </Nav.Item>   


                                ))}
                                </div>
                                <div className="zl_add_currency_column zl_add_currency_btn_col col">
                                    <Link to={'/addcurrency'} className="zl_add_currency_btn_content">
                                        + Add Currency
                                    </Link>
                                </div>
                            </Nav>
                        </div>
                        <Tab.Content>
                            {cuurency.map((item) => (
                            <Tab.Pane eventKey={`tab${item.id}`}  key={item.id}>
                                <WalletDetails id={item.id} coin_data={coin_data} />
                            </Tab.Pane>
                            ))}
                            
                        </Tab.Content>
                    </Tab.Container>
                </section>
            </>
        );
    }   
    
}

export default connect(null, null)(PortfolioModule);
