import React, { useState } from "react";
import { connect } from "react-redux";
import HeadingModule from '../components/Layout/HeadingComponent/Heading';
import "react-datepicker/dist/react-datepicker.css";
import Chart from "react-apexcharts";
import { Sparklines, SparklinesLine, SparklinesSpots } from 'react-sparklines';
import { getprice, get_address_info } from '../helpers/cryptoutils'
import { cuurency } from "../helpers/currency_info";
import { get_omni_data, get_omni_balance } from '../helpers/ayautils';
import { assets_info } from "../helpers/assets_info";

// import { mapStateToProps } from './mappers';

const DashboardModule = (props) => {
    // date picker
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;

    
    
    // chart
    const options = {
        chart: {
            zoom: {
                enabled: true
            },
            stacked: false,
        },
        xaxis: {
            categories: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
            borderColor: '#999',
            yAxisIndex: 0,
            type: 'datetime',
            min: new Date('01 Mar 2012').getTime(),
            tickAmount: 6,
            x: new Date('14 Nov 2012').getTime(),
            label: {
                show: true,
                text: 'Support',
                style: {
                    colors: ['#fff'],
                    background: '#00E396'
                }
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            width: 2,
            curve: "smooth"
        },
        fill: {
            type: 'gradient',
            gradient: {
                shadeIntensity: 1,
                opacityFrom: 0.7,
                opacityTo: 0.2,
                stops: [0, 90, 100]
            }
        },
        // markers: {
        //     size: 0,
        //     style: 'hollow',
        // },
        series: [
            {
                name: "series-1",
                type: 'datetime',
                data: [
                    [1327359600000, 30.95],
                    [1327446000000, 31.34],
                    [1327532400000, 31.18],
                    [1327618800000, 31.05],
                    [1327878000000, 31.00],
                    [1327964400000, 30.95],
                    [1328050800000, 31.24],
                    [1328137200000, 31.29],
                    [1328223600000, 31.85],
                    [1328482800000, 31.86],
                    [1328569200000, 32.28],
                    [1328655600000, 32.10],
                    [1328742000000, 32.65],
                    [1328828400000, 32.21],
                    [1329087600000, 32.35],
                    [1329174000000, 32.44],
                    [1329260400000, 32.46],
                    [1329346800000, 32.86],
                    [1329433200000, 32.75],
                    [1329778800000, 32.54],
                    [1329865200000, 32.33],
                    [1329951600000, 32.97],
                    [1330038000000, 33.41],
                    [1330297200000, 33.27],
                    [1330383600000, 33.27],
                    [1330470000000, 32.89],
                    [1330556400000, 33.10],
                    [1330642800000, 33.73],
                    [1330902000000, 33.22],
                    [1330988400000, 31.99],
                    [1331074800000, 32.41],
                    [1331161200000, 33.05],
                    [1331247600000, 33.64],
                    [1331506800000, 33.56],
                    [1331593200000, 34.22],
                    [1331679600000, 33.77],
                    [1331766000000, 34.17],
                    [1331852400000, 33.82],
                    [1332111600000, 34.51],
                    [1332198000000, 33.16],
                    [1332284400000, 33.56],
                    [1332370800000, 33.71],
                    [1332457200000, 33.81],
                    [1332712800000, 34.40],
                    [1332799200000, 34.63],
                    [1332885600000, 34.46],
                    [1332972000000, 34.48],
                    [1333058400000, 34.31],
                    [1333317600000, 34.70],
                    [1333404000000, 34.31],
                    [1333490400000, 33.46],
                    [1333576800000, 33.59],
                    [1333922400000, 33.22],
                    [1334008800000, 32.61],
                    [1334095200000, 33.01],
                    [1334181600000, 33.55],
                    [1334268000000, 33.18],
                    [1334527200000, 32.84],
                    [1334613600000, 33.84],
                    [1334700000000, 33.39],
                    [1334786400000, 32.91],
                    [1334872800000, 33.06],
                    [1335132000000, 32.62],
                    [1335218400000, 32.40],
                    [1335304800000, 33.13],
                    [1335391200000, 33.26],
                    [1335477600000, 33.58],
                    [1335736800000, 33.55],
                    [1335823200000, 33.77],
                    [1335909600000, 33.76],
                    [1335996000000, 33.32],
                    [1336082400000, 32.61],
                    [1336341600000, 32.52],
                    [1336428000000, 32.67],
                    [1336514400000, 32.52],
                    [1336600800000, 31.92],
                    [1336687200000, 32.20],
                    [1336946400000, 32.23],
                    [1337032800000, 32.33],
                    [1337119200000, 32.36],
                    [1337205600000, 32.01],
                    [1337292000000, 31.31],
                    [1337551200000, 32.01],
                    [1337637600000, 32.01],
                    [1337724000000, 32.18],
                    [1337810400000, 31.54],
                    [1337896800000, 31.60],
                    [1338242400000, 32.05],
                    [1338328800000, 31.29],
                    [1338415200000, 31.05],
                    [1338501600000, 29.82],
                    [1338760800000, 30.31],
                    [1338847200000, 30.70],
                    [1338933600000, 31.69],
                    [1339020000000, 31.32],
                    [1339106400000, 31.65],
                    [1339365600000, 31.13],
                    [1339452000000, 31.77],
                    [1339538400000, 31.79],
                    [1339624800000, 31.67],
                    [1339711200000, 32.39],
                    [1339970400000, 32.63],
                    [1340056800000, 32.89],
                    [1340143200000, 31.99],
                    [1340229600000, 31.23],
                    [1340316000000, 31.57],
                    [1340575200000, 30.84],
                    [1340661600000, 31.07],
                    [1340748000000, 31.41],
                    [1340834400000, 31.17],
                    [1340920800000, 32.37],
                    [1341180000000, 32.19],
                    [1341266400000, 32.51],
                    [1341439200000, 32.53],
                    [1341525600000, 31.37],
                    [1341784800000, 30.43],
                    [1341871200000, 30.44],
                    [1341957600000, 30.20],
                    [1342044000000, 30.14],
                    [1342130400000, 30.65],
                    [1342389600000, 30.40],
                    [1342476000000, 30.65],
                    [1342562400000, 31.43],
                    [1342648800000, 31.89],
                    [1342735200000, 31.38],
                    [1342994400000, 30.64],
                    [1343080800000, 30.02],
                    [1343167200000, 30.33],
                    [1343253600000, 30.95],
                    [1343340000000, 31.89],
                    [1343599200000, 31.01],
                    [1343685600000, 30.88],
                    [1343772000000, 30.69],
                    [1343858400000, 30.58],
                    [1343944800000, 32.02],
                    [1344204000000, 32.14],
                    [1344290400000, 32.37],
                    [1344376800000, 32.51],
                    [1344463200000, 32.65],
                    [1344549600000, 32.64],
                    [1344808800000, 32.27],
                    [1344895200000, 32.10],
                    [1344981600000, 32.91],
                    [1345068000000, 33.65],
                ]
            }
        ]
    }


    

    const [coin_data, set_coin_data] = useState(null);
    

    const asset_data = JSON.parse(localStorage.getItem('aya_omni_balance')).balance.filter((item) => { 
        if(item.id == 0){return false;}
        else{return true;}
    });
    
    
    if(localStorage.getItem('aya_data') !== null && coin_data ===null){
        localStorage.setItem('last_update', new Date().getTime());
        set_coin_data([{data:JSON.parse(localStorage.getItem('aya_data')),price:JSON.parse(localStorage.getItem('aya_price'))},{data:JSON.parse(localStorage.getItem('btc_data')),price:JSON.parse(localStorage.getItem('btc_price'))},{data:JSON.parse(localStorage.getItem('dash_data')),price:JSON.parse(localStorage.getItem('dash_price'))}]);        
    }


    const get_coin_data = async() => { 

        //console.log('Get_coin_Data run');
        if(new Date().getTime() > Number(localStorage.getItem('last_update'))+60000 || localStorage.getItem('f_update') === 'true'){
            //Update only if data is 30sec older
            localStorage.setItem('last_update', new Date().getTime());
            localStorage.setItem('f_update','false');

            const price_data_ = await getprice();
            console.log(price_data_);

            const c_data = [];
            await Promise.all(cuurency.map( async(item) => {
                c_data[item.id] = await get_address_info(item.name.toLowerCase());
                localStorage.setItem(item.name.toLowerCase() + '_data', JSON.stringify(c_data[item.id])) ;
                localStorage.setItem(item.name.toLowerCase() + '_price', JSON.stringify(price_data_[item.id]));
            }));

            console.log('CointData:' + c_data);
            console.log('PRiceData:' + price_data_);

            const _bal = await get_omni_balance(localStorage.getItem("aya_address"));
            const _data = await get_omni_data(localStorage.getItem("aya_address"));
            
            localStorage.setItem('aya_omni_balance', JSON.stringify(_bal)); 
            localStorage.setItem('aya_omni_data', JSON.stringify(_data)); 

            set_coin_data([{data:JSON.parse(localStorage.getItem('aya_data')),price:JSON.parse(localStorage.getItem('aya_price'))},{data:JSON.parse(localStorage.getItem('btc_data')),price:JSON.parse(localStorage.getItem('btc_price'))},{data:JSON.parse(localStorage.getItem('dash_data')),price:JSON.parse(localStorage.getItem('dash_price'))}]); 
            
            
        }
        
    }

    //Create interval
    //const interval = setInterval(get_coin_data(), 60000);
    //+ve symbol <path d="M3.60609 3.60609L2.69695 4.51523C2.36222 4.84996 1.81951 4.84996 1.48477 4.51523C1.15004 4.18049 1.15004 3.63778 1.48477 3.30305L2.39391 2.39391L0 0H6V6L3.60609 3.60609Z" fill="#50E2C2" />

    const update_interval = setInterval(get_coin_data, 10000);

    const ntive_icon = <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.60609 2.39391L2.69695 1.48477C2.36222 1.15004 1.81951 1.15004 1.48477 1.48477C1.15004 1.81951 1.15004 2.36222 1.48477 2.69695L2.39391 3.60609L0 6H6V0L3.60609 2.39391Z" fill="#E3507A" /></svg>;
    const ptive_icon = <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.60609 3.60609L2.69695 4.51523C2.36222 4.84996 1.81951 4.84996 1.48477 4.51523C1.15004 4.18049 1.15004 3.63778 1.48477 3.30305L2.39391 2.39391L0 0H6V6L3.60609 3.60609Z" fill="#50E2C2" /></svg>;

    if(coin_data !== null){

        return (
            <>
                <section className="zl_dashboard_page">
                    <HeadingModule name={'Dashboard'} heading_description={'View and manage your wallet.'}  />
                    
                    <div className="zl_add_currency_content">
                        <h3 className="zl_bottom_content_heading">Wallets</h3>
                        <div className="zl_add_currency_row row">

                        {cuurency.map((item) => (

                            <div className="zl_add_currency_column col" key={'cur_col'+ item.id}>
                                <div className="zl_add_currency_inner_content">
                                    <div className="zl_add_currency_icon_chart">
                                        <div style={{width: '70%'}}>
                                        <img width="28" height="28" src={item.icon} alt="logo"/>
                                        </div>
                                        <Sparklines data={[coin_data[item.id].price.sparkline_in_7d.price[24],coin_data[item.id].price.sparkline_in_7d.price[48],coin_data[item.id].price.sparkline_in_7d.price[72],coin_data[item.id].price.sparkline_in_7d.price[96],coin_data[item.id].price.sparkline_in_7d.price[120],coin_data[item.id].price.sparkline_in_7d.price[144],coin_data[item.id].price.sparkline_in_7d.price[166]]} margin={6} className="zl_add_currency_mini_chart">
                                            <SparklinesLine style={{ strokeWidth: 10, stroke: item.stroke, fill: "none", curve: "smooth" }} />
                                            <SparklinesSpots size={4}
                                                style={{ stroke: item.stroke, strokeWidth: 3, fill: "white" }} />
                                        </Sparklines>
                                    </div>
                                    <div className="zl_add_currency_price">
                                        <div className="zl_add_currency_left_price">
                                            <h3>{item.name}</h3>
                                            <p>{coin_data[item.id].data.balance}</p>
                                        </div>
                                        <div className="zl_add_currency_right_price">
                                            <span className={coin_data[item.id].price.price_change_percentage_24h > 0 ? "" : "zl_add_currency_down_price"}>
                                                {coin_data[item.id].price.price_change_percentage_24h > 0 ? ptive_icon : ntive_icon}
                                                {(coin_data[item.id].price.price_change_percentage_24h).toFixed(2)}%
                                            </span>
                                            <p>{ '$' + (coin_data[item.id].data.balance * coin_data[item.id].price.current_price).toFixed(4)} </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                        </div>
                    </div>
                    <br />
                    <div className="zl_add_currency_content">
                        <h3 className="zl_bottom_content_heading">Assets</h3>
                        <div className="zl_add_currency_row row">
                        {asset_data.map((item) => (

                            <div className="zl_add_currency_column col" key={'cur_col'+ item.id}>
                                <div className="zl_add_currency_inner_content">
                                    <div className="zl_add_currency_icon_chart">
                                                    <div style={{width: '70%'}}>
                                                    <img  width="35" height="28" style={{ visibility: assets_info.filter((itemx) => {return itemx.id == item.id}).length > 0 ? 'none' : 'hidden' }}  src={ assets_info.filter((itemx) => {return itemx.id == item.id}).length > 0 ? assets_info[0].logo : ''} />
                                                    </div>
                                                </div>
                                                <div className="zl_add_currency_price">
                                                    <div className="zl_add_currency_left_price">
                                                        <h3>{item.propertyinfo.propertyname}</h3>
                                                        <h3>ID: {item.id}</h3>
                                                        <p>{item.value/100000000}</p>
                                                    </div>
                                    </div>
                                </div>
                            </div>
                        ))}

                        </div>
                    </div>

                    


                </section>
            </>
        );
    }
    else{

        get_coin_data();
        return (
            <>
                <section className="zl_dashboard_page">
                    <HeadingModule name={'Dashboard'} heading_description={'View and manage your wallet.'}  />
                    <div className="zl_all_page_comman_content">
                        
                        <div className="zl_dashboard_chart">
                            <Chart options={options} series={options.series} type="area" height={350} />
                        </div>
                        <div className="zl_all_page_comman_total_price">
                            <p className="zl_all_page_total_price_heading">Total Balance</p>
                            <h2 className="zl_all_page_total_price_text">$76685.41</h2>
                            <span className="zl_all_page_total_price_up_text">
                                <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4 6.66668V1.33334" stroke="#50E2C2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M2 3.33334L4 1.33334L6 3.33334" stroke="#50E2C2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                0.00%
                            </span>
                        </div>
                    </div>
                    <div className="zl_add_currency_content">
                        <h3 className="zl_bottom_content_heading">Wallets</h3>
                        <div className="zl_add_currency_row row">
                            {cuurency.map((item) => (

                                <div className="zl_add_currency_column col" key={'cur_col'+ item.id}>
                                    <div className="zl_add_currency_inner_content">
                                        <div className="zl_add_currency_icon_chart">
                                            <div style={{width: '70%'}}>
                                            <img width="28" height="28" src={item.icon} alt="logo"/>
                                            </div>
                                            <Sparklines data={[]} margin={6} className="zl_add_currency_mini_chart">
                                                <SparklinesLine style={{ strokeWidth: 10, stroke: item.stroke, fill: "none", curve: "smooth" }} />
                                                <SparklinesSpots size={4}
                                                    style={{ stroke: item.stroke, strokeWidth: 3, fill: "white" }} />
                                            </Sparklines>
                                        </div>
                                        <div className="zl_add_currency_price">
                                            <div className="zl_add_currency_left_price">
                                                <h3>{item.name}</h3>
                                                <p>0</p>
                                            </div>
                                            <div className="zl_add_currency_right_price">
                                                <span className="zl_add_currency_down_price">
                                                    {ptive_icon}
                                                    {0}%
                                                </span>
                                                <p>0 </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            
                        </div>
                    </div>
                </section>
            </>
        );
    }
    
}

export default connect(null, null)(DashboardModule);
