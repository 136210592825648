import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import HeadingModule from '../components/Layout/HeadingComponent/Heading';
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import { decrypt , encrypt} from '../helpers/cryptoutils'
import { Modal , Button} from 'react-bootstrap'

const SettingModule = ({themHandler}) => {

    const [color, setColor] = useState(true);
    const [showp, setshowp] = useState(false);
    const [pvt_key, setpvt] = useState(["","",""]);

    const [wpass,setwpass] = useState(sessionStorage.getItem("sess_pass"));
    const [showpp, setshowpp] = useState(false);

    const [showppp, setshowppp] = useState(false);

    useEffect(() => {
        if(typeof window !== 'undefined') {
            setColor(localStorage.getItem("themColor") === "zl_light_theme_active" ? false : localStorage.getItem("themColor") !== null && true);
        }
    }, []);    

    const showkeys = () =>{

        setpvt([decrypt(localStorage.getItem("aya_key"),sessionStorage.getItem("sess_pass")) , decrypt(localStorage.getItem("btc_key"),sessionStorage.getItem("sess_pass")),decrypt(localStorage.getItem("dash_key"),sessionStorage.getItem("sess_pass")) ]);
        setshowp(true);
        
    }

    const hidekeys = () =>{

        setpvt(["","",""]);
        setshowp(false);
    }

    const reset_pass = () => {
        const mn = decrypt(localStorage.getItem('mnemonic'),sessionStorage.getItem("sess_pass"));
        const p_aya = decrypt(localStorage.getItem('aya_key'),sessionStorage.getItem("sess_pass"));
        const p_btc = decrypt(localStorage.getItem('btc_key'),sessionStorage.getItem("sess_pass"));
        const p_dash = decrypt(localStorage.getItem('dash_key'),sessionStorage.getItem("sess_pass"));
        localStorage.setItem('aya_key', encrypt(p_aya.toString(),wpass));
        localStorage.setItem('btc_key', encrypt(p_btc.toString(),wpass));
        localStorage.setItem('dash_key', encrypt(p_dash.toString(),wpass));
        localStorage.setItem('mnemonic', encrypt(mn.toString(),wpass));
        sessionStorage.setItem("sess_pass",wpass);
        setshowpp(false);
        setshowppp(true);
    }

    return (
        <>
            <section className="zl_settings_page">
                <HeadingModule name={'Settings'} />
                <div className="zl_setting_list">
                    <Link to={'/restorewallet'} className="zl_setting_list_items">
                        <div className="zl_setting_items_heading_peregraph">
                            <h3>Restore Wallet</h3>
                            <p>Overwrite your current mobile wallet using a 12-word recovery pharse.</p>
                        </div>
                        <div className="zl_setting_items_right_text">
                            <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 1L6.08833 6L1 11" stroke="#828CAE" strokeWidth="2.4" />
                            </svg>
                        </div>
                    </Link>
                    <Link to={'#'} onClick={showkeys} className="zl_setting_list_items">
                        <div className="zl_setting_items_heading_peregraph">
                            <h3>View Private Keys</h3>
                            <p>View your AYA , BTC , DASH private keys</p>
                        </div>
                        <div className="zl_setting_items_right_text">
                            <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 1L6.08833 6L1 11" stroke="#828CAE" strokeWidth="2.4" />
                            </svg>
                        </div>
                    </Link>
                    <Link to={'#'} onClick={() => {setshowpp(true)}} className="zl_setting_list_items">
                        <div className="zl_setting_items_heading_peregraph">
                            <h3>Change Password</h3>
                            <p>Change your current password</p>
                        </div>
                        <div className="zl_setting_items_right_text">
                            <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 1L6.08833 6L1 11" stroke="#828CAE" strokeWidth="2.4" />
                            </svg>
                        </div>
                    </Link>
                    <div className="zl_setting_list_items">
                        <div className="zl_setting_items_heading_peregraph">
                            <h3>Change Theme Mode</h3>
                            <p>Toggle between light and dark theme.</p>
                        </div>
                        <Form.Check
                            type="switch"
                            id='checkbox2'
                            label=""
                            className="zl_custom_currency_checkbox"
                            checked={color}
                            onChange={() => {
                                setColor(!color);
                                themHandler(color);
                            }}
                        />
                    </div>
                <Modal size="lg" style={{'textAlign':'center'}} centered show={showp} onHide={hidekeys} backdrop={true} keyboard={false} >
                    <Modal.Header closeButton>
                    <Modal.Title>Private Keys</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        AYA Private Key : {pvt_key[0]}
                        <br />
                        BTC Private Key : {pvt_key[1]}
                        <br />
                        DASH Private Key: {pvt_key[2]}
                        <br />
                    </Modal.Body>
                </Modal>

                <Modal style={{'textAlign':'center'}} centered show={showpp} onHide={() => setshowpp(false)} backdrop={true} keyboard={false} >
                    <Modal.Header closeButton>
                    <Modal.Title>Enter New Password</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="zl_login_input_content position-relative">
                        <input type="password" className="zl_login_input" id={`pass_input`} onChange={(event) => {setwpass(event.target.value)}} placeholder='**************'  />
                        </div>
                        <br />
                        <div className="zl_login_btn">
                            <Button id="pass_btn" onClick={ reset_pass } className="zl_send_currency_btn btn btn-primary">Change Password</Button>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal size="lg" style={{'textAlign':'center'}} centered show={showppp} onHide={() => setshowppp(false)} backdrop={true} keyboard={false} >
                    <Modal.Header closeButton>
                    <Modal.Title>Password Updated</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Password Updated
                    </Modal.Body>
                </Modal>
                </div>
            </section>
            
        </>
    );
}

export default connect(null, null)(SettingModule);
