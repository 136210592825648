import React , { useState } from "react";
import { connect } from "react-redux";
import HeadingModule from '../components/Layout/HeadingComponent/Heading';
import { Tab, Nav } from 'react-bootstrap';
import { getprice, get_address_info } from '../helpers/cryptoutils'
import { cuurency } from "../helpers/currency_info";
import { get_omni_data, get_omni_balance } from '../helpers/ayautils';
import AssetIssueDetails from '../components/Layout/AssetComponent/issueasset';
import { Link } from 'react-router-dom';
import { assets_info } from "../helpers/assets_info";

const IssueAssetsModule = () => {

    
    const asset_data = JSON.parse(localStorage.getItem('aya_omni_balance')).balance.filter((item) => { 
        if((item.propertyinfo.issuer === localStorage.getItem('aya_address')) && (item.propertyinfo.managedissuance === true )){return true;}
        else{return false;}
    });

    const [coin_data, set_coin_data] = useState(null);
    
    


    const get_coin_data = async() => { 

        //console.log('Running Coin_get_data');

        if(new Date().getTime() > Number(localStorage.getItem('last_update'))+60000  || localStorage.getItem('f_update') === 'true'){
            //Update only if data is 30sec older
            localStorage.setItem('last_update', new Date().getTime());
            localStorage.setItem('f_update','false');

            const price_data_ = await getprice();
            console.log(price_data_);

            const c_data = [];
            await Promise.all(cuurency.map( async(item) => {
                c_data[item.id] = await get_address_info(item.name.toLowerCase());
                localStorage.setItem(item.name.toLowerCase() + '_data', JSON.stringify(c_data[item.id])) ;
                localStorage.setItem(item.name.toLowerCase() + '_price', JSON.stringify(price_data_[item.id]));
            }));

            console.log('CointData:' + c_data);
            console.log('PRiceData:' + price_data_);

            const _bal = await get_omni_balance(localStorage.getItem("aya_address"));
            const _data = await get_omni_data(localStorage.getItem("aya_address"));

            localStorage.setItem('aya_omni_balance', JSON.stringify(_bal)); 
            localStorage.setItem('aya_omni_data', JSON.stringify(_data)); 

            set_coin_data([{data:JSON.parse(localStorage.getItem('aya_data')),price:JSON.parse(localStorage.getItem('aya_price'))},{data:JSON.parse(localStorage.getItem('btc_data')),price:JSON.parse(localStorage.getItem('btc_price'))},{data:JSON.parse(localStorage.getItem('dash_data')),price:JSON.parse(localStorage.getItem('dash_price'))}]); 
            
        }
        
    }

    //Create interval
    //const interval = setInterval(get_coin_data(), 60000);
    const update_interval = setInterval(get_coin_data, 10000);
    // /<h3>Issuer: { assets_info.filter((itemx) => {return itemx.id == item.id}).length > 0 ? assets_info[0].issuer : item.propertyinfo.issuer}</h3>
    console.log(asset_data);
    return (
        <>
            <section className="zl_wallets_page">
                <HeadingModule name={'Manage Assets'} heading_description={'View managed OmniAYA Assets created by you.'} />
                <Tab.Container id="left-tabs-example" defaultActiveKey="tab1">
                    <div className="zl_add_currency_content">
                        <h3 className="zl_bottom_content_heading">OmniAYA Assets</h3>
                        <Nav className="zl_add_currency_row row">
                            <div className="zl_currency_column_sub_row">
                                {asset_data.map((item) => (
                                    
                                    <Nav.Item className="zl_add_currency_column col" key={'cur_col'+ item.id}>
                                        <Nav.Link eventKey={`tab${item.id}`} className={"zl_add_currency_inner_content zl_add_aryacoin_currency"}>
                                            <div className="zl_add_currency_icon_chart">
                                                <div style={{width: '70%'}}>
                                                <img  width="28" height="28" style={{ visibility: assets_info.filter((itemx) => {return itemx.id == item.id}).length > 0 ? 'none' : 'hidden'}} src={ assets_info.filter((itemx) => {return itemx.id == item.id}).length > 0 ? assets_info[0].logo : ''} />
                                                </div>
                                            </div>
                                            <div className="zl_add_currency_price">
                                                <div className="zl_add_currency_left_price">
                                                    <h3>{item.propertyinfo.propertyname}</h3>
                                                    <h3>ID: {item.id}</h3>
                                                    
                                                    <h3>Verified: { assets_info.filter((itemx) => {return itemx.id == item.id}).length > 0 ? 'YES' : 'NO'}</h3>
                                                    <p>{item.value/100000000}</p>
                                                </div>
                                            </div>
                                        </Nav.Link>
                                    </Nav.Item>   
                                ))}
                            </div>
                            <div className="zl_add_currency_column zl_add_currency_btn_col col">
                                    <Link to={'/createasset'} className="zl_add_currency_btn_content">
                                        + Create Asset
                                    </Link>
                            </div>
                        </Nav>
                    </div>
                    <Tab.Content>

                            {asset_data.map((item) => (
                            <Tab.Pane eventKey={`tab${item.id}`}  key={item.id}>
                                <AssetIssueDetails asset_data={item} coin_data={coin_data} />
                            </Tab.Pane>
                            ))}
                            
                    </Tab.Content>
                </Tab.Container>
            </section>
        </>
    );
}


export default connect(null, null)(IssueAssetsModule);
