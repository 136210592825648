import React, { useState } from "react";
import { connect } from "react-redux";
import HeadingModule from '../components/Layout/HeadingComponent/Heading';
import { FormControl, Button, Modal, FloatingLabel, FormSelect } from 'react-bootstrap'
import { aya_omni_issue_fixed, aya_omni_issue_managed, aya_omni_create_NFT } from "../helpers/ayautils";
import NotificationModal from "../components/Modals/Notification/Notification";
import { decrypt } from '../helpers/cryptoutils';


const CreateAssetModule = () => {

    const [token_type, set_token_type] = useState("fixed");
    const [token_name, set_token_name] = useState(null);
    const [token_desc, set_token_desc] = useState('');
    const [token_URL, set_token_URL] = useState('');
    const [token_amount, set_token_amount] = useState(0);

    const [show, setShow] = useState([false,'static']);
    const [show_status, setShow_status] = useState(['Recovering Keys','Recovering Keys']);
    const handleClose = () => setShow([false,'static']);
    const handleShow = () => setShow([true,'static']);

    const [wpass, setwpass] = useState(sessionStorage.getItem("sess_pass"));
    const [showp, setshowp] = useState(false);


    

    const send_tx = async() =>{
        
        setshowp(false);
        if(wpass !== null ){
            setShow_status(['Creating and Sending Transaction','Creating and Sending Transaction']);
            handleShow();
            try{
                
                if(token_type === "fixed"){

                
                    if(token_name === null){
                        setShow_status(['Error','Error: Token name is required.']);
                    }
                    else if(token_amount === 0){
                        setShow_status(['Error','Error: Token amount cannot be 0.']);
                    }
                    else{
                        const tx_stats = await aya_omni_issue_fixed(localStorage.getItem("aya_address"),decrypt(localStorage.getItem("aya_key"),wpass),token_name+"\0",token_amount,token_URL+"\0",token_desc + "\0");
                        if(tx_stats[0] !== true){
                            setShow_status(['Error','Error: ' + tx_stats[1]]);
                        }
                        else{
                            const stat = tx_stats[1] + ';' + token_name + ';' + token_URL + ';' + token_desc;
                            setShow_status(['Success',stat]);
                        }
                    }
                    
                }
                else{
        
                    if(token_type === "nft"){
                        if(token_name === null){
                            setShow_status(['Error','Error: Token name is required.']);
                        }
                        else{
                            const tx_stats =  await aya_omni_create_NFT(localStorage.getItem("aya_address"),decrypt(localStorage.getItem("aya_key"),wpass),token_name+"\0",token_amount,token_URL+"\0",token_desc + "\0");
                            if(tx_stats[0] !== true){
                                setShow_status(['Error','Error: ' + tx_stats[1]]);
                            }
                            else{
                                const stat = tx_stats[1] + ';' + token_name + ';' + token_URL + ';' + token_desc;
                                setShow_status(['Success',stat]);
                            }
                        }
                    }
                    else{
                        if(token_name === null){
                            setShow_status(['Error','Error: Token name is required.']);
                        }
                        else{
                            const tx_stats =  await aya_omni_issue_managed(localStorage.getItem("aya_address"),decrypt(localStorage.getItem("aya_key"),wpass),token_name+"\0",token_amount,token_URL+"\0",token_desc + "\0");
                            if(tx_stats[0] !== true){
                                setShow_status(['Error','Error: ' + tx_stats[1]]);
                            }
                            else{
                                const stat = tx_stats[1] + ';' + token_name + ';' + token_URL + ';' + token_desc;
                                setShow_status(['Success',stat]);
                            }
                        }
                    }
                    
                    
                    
                }
                

            }
            catch(e){
                setShow_status(['Error','API Error Occured Please try again later']);
                setwpass(null);
            }
            setShow([true,true]);
            localStorage.setItem('f_update','true');
        }
        else{
            setshowp(true);
        }
        
    };

    if(token_type === "fixed"){
        
        return (
            <>
                <section className="zl_wallets_page">
                    <HeadingModule name={'Create new OmniAYA asset'} heading_description={'Create your own OmniAYA Assets.'} />
                    <div className="zl_add_currency_content">
                        <h3 className="zl_bottom_content_heading">Create new asset</h3>
                            
                    </div>
                    <div className="zl_send_recive_content_row">
                        <div className="zl_send_recive_content_column">
                            <div className="zl_send_recive_inner_content">
                                <div className="zl_send_currency_input_content">
                                    <FloatingLabel style={{ color: "#C0C3CC" }}  controlId="tok_name" label="Token Name">
                                        <FormControl as="input" htmlSize={200} className="zl_send_currency_input" type="text" placeholder="Token Name"  onChange={(event) => {set_token_name(event.target.value)}} />
                                    </FloatingLabel>
                                    <br/>
                                </div>
                                <div className="zl_send_currency_input_content">
                                    <FloatingLabel style={{ color: "#C0C3CC" }}  controlId="tok_desc" label="Token Description (Optional)">
                                        <FormControl className="zl_send_currency_input" as="input" htmlSize={500} placeholder="Token Description (Optional)"  onChange={(event) => {set_token_desc(event.target.value)}} />
                                    </FloatingLabel>
                                    <br/>
                                </div>
                                <div className="zl_send_currency_input_content">
                                    <FloatingLabel style={{ color: "#C0C3CC" }} controlId="tok_Website" label="Token Website (Optional)">
                                        <FormControl as="input" htmlSize={200} className="zl_send_currency_input" type="text" placeholder="Token Website (Optional)" onChange={(event) => {set_token_URL(event.target.value)}} />
                                    </FloatingLabel>
                                    <br/>
                                </div>

                                <div className="zl_send_currency_input_content">
                                    <FloatingLabel style={{ color: "#C0C3CC" }} controlId="tok_amt" label="Token Amount">
                                        <FormControl as="input" htmlSize={200} className="zl_send_currency_input" type="number" placeholder="Token Amount" onChange={(event) => {set_token_amount(event.target.value)}} />
                                    </FloatingLabel>
                                    <br/>
                                </div>
                                <div className="zl_send_currency_input_content">
                                    <FloatingLabel style={{ color: "#C0C3CC" }} controlId="tok_atype" label="Token Type">
                                        <FormSelect style={{width: '105%', height:'105%'}} className="zl_send_currency_input"  defaultValue={token_type} onChange={(event) => {set_token_type(event.target.value);}}>
                                            <option value="fixed">Fixed Supply</option>
                                            <option value="managed">Managed Supply</option>
                                        </FormSelect>
                                    </FloatingLabel>
                                    <br/>
                                </div>
                                <br/>
                                <div className="zl_send_currency_btn_text">
                                    <Button className="zl_send_currency_btn" id="send_but" onClick={send_tx}>Create Token</Button>
                                    <div className="zl_send_currency_text">
                                        <p>Network Fee<span>0.000040001 AYA</span></p>
                                    </div>
                                </div>
                                <NotificationModal show_status={show_status} show={show} type={'omni_create'}/> 
                                <Modal style={{'textAlign':'center'}} centered show={showp} onHide={() => setshowp(false)} backdrop={true} keyboard={false} >
                                    <Modal.Header closeButton>
                                    <Modal.Title>Enter Password</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div className="zl_login_input_content position-relative">
                                            <input type="password" className="zl_login_input" id={`pass_input`} onChange={(event) => {setwpass(event.target.value)}} placeholder='********'  />
                                        </div>
                                        <br />
                                        <div className="zl_login_btn">
                                            <Button id="pass_btn" onClick={ () => {send_tx();} } className="zl_send_currency_btn btn btn-primary"> {'Send Transaction' }</Button>
                                        </div>
                                    </Modal.Body>
                                </Modal>  
                                                                    
                            </div>
                        </div>
                    </div>
                </section>
                
            </>
        );

    }
    else{

        return (
            <>
                <section className="zl_wallets_page">
                    <HeadingModule name={'Create new OmniAYA asset'} heading_description={'Create your own OmniAYA Assets.'} />
                    <div className="zl_add_currency_content">
                        <h3 className="zl_bottom_content_heading">Create new asset</h3>
                    </div>
                    <div className="zl_send_recive_content_row">
                        <div className="zl_send_recive_content_column">
                            <div className="zl_send_recive_inner_content">
                                <div className="zl_send_currency_input_content">
                                    <FloatingLabel style={{ color: "#C0C3CC" }}  controlId="tok_name" label="Token Name">
                                        <FormControl as="input" htmlSize={200} className="zl_send_currency_input" type="text" placeholder="Token Name"  onChange={(event) => {set_token_name(event.target.value)}} />
                                    </FloatingLabel>
                                    <br/>
                                </div>
                                <div className="zl_send_currency_input_content">
                                    <FloatingLabel style={{ color: "#C0C3CC" }} controlId="tok_desc" label="Token Description (Optional)">
                                        <FormControl className="zl_send_currency_input" as="input" htmlSize={500} placeholder="Token Description (Optional)"  onChange={(event) => {set_token_desc(event.target.value)}} />
                                    </FloatingLabel>
                                    <br/>
                                </div>
                                <div className="zl_send_currency_input_content">
                                    <FloatingLabel style={{ color: "#C0C3CC" }} controlId="tok_Website" label="Token Website (Optional)">
                                        <FormControl as="input" htmlSize={200} className="zl_send_currency_input" type="text" placeholder="Token Website (Optional)" onChange={(event) => {set_token_URL(event.target.value)}} />
                                    </FloatingLabel>
                                    <br/>
                                </div>
                                
                                <div className="zl_send_currency_input_content">
                                    <FloatingLabel style={{ color: "#C0C3CC" }} controlId="tok_atype" label="Token Type">
                                        <FormSelect style={{width: '105%', height:'105%'}} className="zl_send_currency_input"  defaultValue={token_type} onChange={(event) => {set_token_type(event.target.value);}}>
                                            <option value="fixed">Fixed Supply</option>
                                            <option value="managed">Managed Supply</option>
                                        </FormSelect>
                                    </FloatingLabel>
                                    <br />
                                </div>
                                <br/>
                                <div className="zl_send_currency_btn_text">
                                    <Button className="zl_send_currency_btn" onClick={send_tx}>Create Token</Button>
                                    <div className="zl_send_currency_text">
                                        <p>Network Fee<span>0.000040001 AYA</span></p>
                                    </div>
                                </div>
                                <NotificationModal show_status={show_status} show={show} type={'omni_create'}/>  
                                <Modal show={showp} onHide={() => setshowp(false)} backdrop={true} keyboard={false} >
                                    <Modal.Header closeButton>
                                    <Modal.Title>Enter Password</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div className="zl_login_input_content position-relative">
                                            <input type="password" className="zl_login_input" id={`pass_input`} onChange={(event) => {setwpass(event.target.value)}} placeholder='********'  />
                                        </div>
                                        <br />
                                        <div className="zl_login_btn">
                                            <Button id="pass_btn" onClick={ () => {send_tx();} } className="zl_send_currency_btn btn btn-primary"> {'Send Transaction' }</Button>
                                        </div>
                                    </Modal.Body>
                                </Modal>                                      
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );

    }
    

}

export default connect(null, null)(CreateAssetModule);
