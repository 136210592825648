import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { Modal, Spinner , Button } from 'react-bootstrap'
import {gen_mnemonic,verify_mnemonic} from '../helpers/cryptoutils'
import {gen_ayakey} from '../helpers/ayautils'
import {gen_btckey} from '../helpers/btcutils'
import {gen_dashkey} from '../helpers/dashutils'
import { getprice, get_address_info , encrypt , decrypt} from '../helpers/cryptoutils'
import { cuurency } from "../helpers/currency_info";
import { get_omni_data, get_omni_balance } from '../helpers/ayautils';

// import { mapStateToProps } from './mappers';

const LogInModule = ({ navigation }) => {

    const [show, setShow] = useState(false);
    const [show_status, setShow_status] = useState('Recovering Keys');
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const [wpass, setwpass] = useState(sessionStorage.getItem("sess_pass"));
    const [showp, setshowp] = useState(false);
    // 0 for login , 1 for register
    const [use_function, setfunc] = useState(0);
    const [sback, setsback] = useState('static');
    const get_coin_data = async() => { 

        //console.log('Running Coin_get_data');

        if(new Date().getTime() > Number(localStorage.getItem('last_update'))+60000  || localStorage.getItem('f_update') === 'true'){

            
            //Update only if data is 30sec older
            localStorage.setItem('last_update', new Date().getTime());
            localStorage.setItem('f_update','false');

            const price_data_ = await getprice();
            console.log(price_data_);

            const c_data = [];
            await Promise.all(cuurency.map( async(item) => {
                c_data[item.id] = await get_address_info(item.name.toLowerCase());
                localStorage.setItem(item.name.toLowerCase() + '_data', JSON.stringify(c_data[item.id])) ;
                localStorage.setItem(item.name.toLowerCase() + '_price', JSON.stringify(price_data_[item.id]));
            }));

            console.log('CointData:' + c_data);
            console.log('PRiceData:' + price_data_);

            const _bal = await get_omni_balance(localStorage.getItem("aya_address"));
            const _data = await get_omni_data(localStorage.getItem("aya_address"));

            localStorage.setItem('aya_omni_balance', JSON.stringify(_bal)); 
            localStorage.setItem('aya_omni_data', JSON.stringify(_data)); 

            //set_coin_data([{data:JSON.parse(localStorage.getItem('aya_data')),price:JSON.parse(localStorage.getItem('aya_price'))},{data:JSON.parse(localStorage.getItem('btc_data')),price:JSON.parse(localStorage.getItem('btc_price'))},{data:JSON.parse(localStorage.getItem('dash_data')),price:JSON.parse(localStorage.getItem('dash_price'))}]); 
            
        }
        
    }

    const login = async () => {

        console.log('running login');
        if(wpass !== null ){
            
            //localStorage.clear();
            setshowp(false);
            handleShow();            
            if(localStorage.getItem("mnemonic") === null){
                const mn = document.getElementById('input1').value.toLowerCase().replace( /[^a-z]/g, '' )+ " " + document.getElementById('input2').value.toLowerCase().replace( /[^a-z]/g, '' )+ " " + document.getElementById('input3').value.toLowerCase().replace( /[^a-z]/g, '' )+ " " + document.getElementById('input4').value.toLowerCase().replace( /[^a-z]/g, '' )+ " " + document.getElementById('input5').value.toLowerCase().replace( /[^a-z]/g, '' )+ " " + document.getElementById('input6').value.toLowerCase().replace( /[^a-z]/g, '' )+ " " + document.getElementById('input7').value.toLowerCase().replace( /[^a-z]/g, '' )+ " " + document.getElementById('input8').value.toLowerCase().replace( /[^a-z]/g, '' )+ " " + document.getElementById('input9').value.toLowerCase().replace( /[^a-z]/g, '' )+ " " + document.getElementById('input10').value.toLowerCase().replace( /[^a-z]/g, '' )+ " " + document.getElementById('input11').value.toLowerCase().replace( /[^a-z]/g, '' )+ " " + document.getElementById('input12').value;
                //Hardcode mnemonic
                if(verify_mnemonic(mn)){
                    localStorage.setItem('mnemonic', encrypt(mn.toString(),wpass));
                    const p_aya = gen_ayakey(mn);
                    const p_btc = gen_btckey(mn);
                    const p_dash = gen_dashkey(mn);

                    localStorage.setItem('aya_key', encrypt(p_aya.toString(),wpass));
                    localStorage.setItem('btc_key', encrypt(p_btc.toString(),wpass));
                    localStorage.setItem('dash_key', encrypt(p_dash.toString(),wpass));
                    sessionStorage.setItem("sess_pass", wpass);
                    setShow_status('Recovering Keys');
                    try{
                        await get_coin_data();
                        //return history.push("/dashboard");
                        
                    }
                    catch(e){
                        //setShow_status('API error occured please try again later');
                        
                    }
                    window.location.replace("/dashboard");
                }
                else{
                    setShow_status('Invalid Mnemonic');
                    setsback(true);
                    setwpass(null);

                }
                
            }
            else{
                if(verify_mnemonic(decrypt(localStorage.getItem("mnemonic"),wpass) )){

                    const mn = decrypt(localStorage.getItem("mnemonic"),wpass);
                    const p_aya = gen_ayakey(mn);
                    const p_btc = gen_btckey(mn);
                    const p_dash = gen_dashkey(mn);

                    localStorage.setItem('aya_key', encrypt(p_aya.toString(),wpass));
                    localStorage.setItem('btc_key', encrypt(p_btc.toString(),wpass));
                    localStorage.setItem('dash_key', encrypt(p_dash.toString(),wpass));
                    sessionStorage.setItem("sess_pass", wpass);
                    setShow_status('Recovering Keys');
                    try{
                        await get_coin_data();
                        //return history.push("/dashboard");
                       
                    }
                    catch(e){
                        //setShow_status('API error occured please try again later');
                        
                    }
                    window.location.replace("/dashboard");
                }
                else{
                    setShow_status('Invalid Password');
                    setsback(true);
                    setwpass(null);
                }
            }
            
        }
        else{

            setshowp(true);
            setsback('static');
            setfunc(0);

        }
        
        
        
        

        
    };

    const register = async () => {

        if(wpass !== null){
            console.log('running reg');
            setshowp(false);
            handleShow();
            const mn = gen_mnemonic();
            //Set in storage
            
            localStorage.setItem('mnemonic', encrypt(mn.toString(),wpass));
            const p_aya = gen_ayakey(mn);
            const p_btc = gen_btckey(mn);
            const p_dash = gen_dashkey(mn);

            localStorage.setItem('aya_key', encrypt(p_aya.toString(),wpass));
            localStorage.setItem('btc_key', encrypt(p_btc.toString(),wpass));
            localStorage.setItem('dash_key', encrypt(p_dash.toString(),wpass));

            setShow_status('Gathering Data');
            sessionStorage.setItem("sess_pass", wpass);
            await get_coin_data();
            //return history.push("/securebackup");
            //history.push("/securebackup");
            window.location.replace("/securebackup");
        }
        else{
            setshowp(true);
            setsback('static');
            setfunc(1);
        }
        

    };

    const inputField = [1,2,3,4,5,6,7,8,9,10,11,12];
    // console.log("state",state);
    
    localStorage.setItem('themColor','zl_page_dark_mode');
    
    return (
            
        <section className="zl_login_section">
            <div className="zl_login_content container">
                <div className="zl_login_heading_text">
                    <h3 className="zl_login_heading">Login</h3>
                    <p className="zl_login_peregraph">Login using your 12 word passphrase.</p>
                </div>
                <div className="zl_login_row row">
                    {inputField.map((inputValue,i) => (
                        <div className="zl_login_col_3 col-lg-3 col-md-6" key={inputValue}>
                            <div className="zl_login_input_content position-relative">
                                <p className="zl_login_input_text">{inputValue}</p>
                                <input type="text" className="zl_login_input" id={`input${inputValue}`} name={`input${inputValue}`} placeholder='****'  />
                            </div>
                        </div>
                    ))}
                </div>
                <div className="zl_login_btn">
                    <Link to={'#'} id="login_btn" onClick={login} className="mx-auto">{localStorage.getItem('mnemonic')=== null ? 'Login' : 'Unlock Account' }</Link>
                </div>

                <div className="zl_login_btn">
                    <Link to={'#'} id="register_btn" onClick={register} className="mx-auto">Create a new account</Link>
                </div>
                <Modal show={show} onHide={handleClose} backdrop={sback} keyboard={false} >
                    <Modal.Header>
                    <Modal.Title>{show_status}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    {show_status}
                    <Spinner animation="border" />
                    </Modal.Body>
                </Modal>

                <Modal style={{'textAlign':'center'}} centered show={showp} onHide={() => setshowp(false)} backdrop={true} keyboard={false} >
                    <Modal.Header closeButton>
                    <Modal.Title>Enter Password</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="zl_login_input_content position-relative">
                        <input type="password" className="zl_login_input" id={`pass_input`} onChange={(event) => {setwpass(event.target.value)}} placeholder='********'  />
                        </div>
                        <br />
                        <div className="zl_login_btn">
                            <Button id="pass_btn" onClick={ () => {if(use_function === 0) {login();} else{register();}} } className="zl_send_currency_btn btn btn-primary"> {use_function === 0 ? 'Login' : 'Register' }</Button>
                        </div>
                    </Modal.Body>
                </Modal>

            </div>
        </section>
    );
    
}

export default connect(null, null)(LogInModule);