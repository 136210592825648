import React, { useState } from "react";
import { connect } from "react-redux";
import { Modal, Spinner } from 'react-bootstrap'

const NotificationModal = (props) => {

    const { show_status, show , type} = props;
    const [hide, sethide] = useState(false);

    const stats = show_status[1].split(';');

    if(show[1] === 'static' && hide === true){
        sethide(false);
    }
    
    if(hide === false){

        
        if(show[1] === true){

            if(show_status[0] === 'Success'){
                if(type === 'omni_send'){

                    return(
                        <>
                            <Modal size="lg" show={show[0]} onHide={() => {sethide(true)}} backdrop={show[1]} keyboard={false}  centered>
                                <Modal.Header>
                                <Modal.Title>{show_status[0]}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <p>Transaction ID: {stats[0]}</p>
                                    <p>Amount: {stats[1]}</p>
                                    <p>Sent to: {stats[2]}</p>
                                    <a target="_blank"  rel="noopener noreferrer"href={'https://omniexplorer.aryacoin.io/tx/'+stats[0]} className="mx-auto">View Transaction</a>
                                    <p>Note: Your transaction will be visible in the wallet once it is confirmed.</p>
                                </Modal.Body>
                            </Modal> 
                        </>
                    );
                }
                else if(type === 'omni_create')
                {
                    return(
                        <>
                            <Modal size="lg" show={show[0]} onHide={() => {sethide(true)}} backdrop={show[1]} keyboard={false}  centered>
                                <Modal.Header>
                                <Modal.Title>{show_status[0]}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <p>Transaction ID: {stats[0]}</p>
                                    <p>Token Name: {stats[1]}</p>
                                    <p>Token Website: {stats[2]}</p>
                                    <p>Token Description: {stats[3]}</p>
                                    <a target="_blank"  rel="noopener noreferrer"href={'https://omniexplorer.aryacoin.io/tx/'+stats[0]} className="mx-auto">View Transaction</a>
                                    <p>Note: Your transaction will be visible in the wallet once it is confirmed.</p>
                                </Modal.Body>
                            </Modal> 
                        </>
                    );
                }
                else if(type === 'omni_issue'){
                    return(
                        <>
                            <Modal size="lg" show={show[0]} onHide={() => {sethide(true)}} backdrop={show[1]} keyboard={false}  centered>
                                <Modal.Header>
                                <Modal.Title>{show_status[0]}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <p>Transaction ID: {stats[0]}</p>
                                    <p>Amount: {stats[1]}</p>
                                    <a target="_blank"  rel="noopener noreferrer"href={'https://omniexplorer.aryacoin.io/tx/'+stats[0]} className="mx-auto">View Transaction</a>
                                    <p>Note: Your transaction will be visible in the wallet once it is confirmed.</p>
                                </Modal.Body>
                            </Modal> 
                        </>
                    );
                }
                else if(type === 'tx'){
                    return(
                        <>
                            <Modal size="lg" show={show[0]} onHide={() => {sethide(true)}} backdrop={show[1]} keyboard={false}  centered>
                                <Modal.Header>
                                <Modal.Title>{show_status[0]}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <p>Transaction ID: {stats[0]}</p>
                                    <p>Amount: {stats[1]}</p>
                                    <p>Sent to: {stats[2]}</p>
                                    <a target="_blank"  rel="noopener noreferrer"href={stats[3]} className="mx-auto">View Transaction</a>
                                    <p>Note: Your transaction will be visible in the wallet once it is confirmed.</p>
                                </Modal.Body>
                            </Modal> 
                        </>
                    );
                }
                else{
                    return(
                        <>
                            <Modal size="lg" show={show[0]} onHide={() => {sethide(true)}} backdrop={show[1]} keyboard={false}  centered>
                                <Modal.Header>
                                <Modal.Title>{show_status[0]}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    {show_status[1]}
                                </Modal.Body>
                            </Modal> 
                        </>
                    );
                }
            }
            else{
                return(
                    <>
                        <Modal size="lg" show={show[0]} onHide={() => {sethide(true)}} backdrop={show[1]} keyboard={false}  centered>
                            <Modal.Header>
                            <Modal.Title>{show_status[0]}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {show_status[1]}
                            </Modal.Body>
                        </Modal> 
                    </>
                );
            }
            
            
        }
        else{

            return(
                <>
                    <Modal size="lg" show={show[0]} onHide={() => {sethide(true)}} backdrop={show[1]} keyboard={false}  centered>
                        <Modal.Header>
                        <Modal.Title>{show_status[0]}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {show_status[1]}
                            <Spinner animation="border" />
                        </Modal.Body>
                    </Modal> 
                </>
            );
    
        }
    }
    
    else{
        return(<></>);
    }    


}

export default connect(null, null)(NotificationModal);
