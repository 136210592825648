import { routes } from '../shared/constants';

// import Homepage from './Homepage';
import Dashboard from './Dashboard';
import Welcome from './Welcome';
import LogIn from './LogIn';
import Portfolio from './Portfolio';
import AddCurrency from './AddCurrency';
import Wallets from './Wallets';
import History from './History';
import SecureBackup from './SecureBackup';
import Setting from './Settings';
import RestoreWallet from './RestoreWallet';
import Currency from './Currency';
import Assets from './Assets';
import CreateAsset from './CreateAsset';
import IssueAsset from './IssueAsset';
import NFT  from './NFT';
import BridgePage  from './Bridge';
import BridgeReprocessPage  from './BridgeReprocess';
import AyaDaoPage  from './AyaDao';


/*if(localStorage.getItem('mnemonic') === null || sessionStorage.getItem("sess_pass") === null){

	export default {
		// [routes.homepage]: Homepage,
		[routes.dashboardpage]: LogIn,
		[routes.welcomepage]: Welcome,
		[routes.loginpage]: LogIn,
		[routes.portfoliopage]: LogIn,
		[routes.addcurrencypage]: LogIn,
		[routes.walletspage]: LogIn,
		[routes.historypage]: LogIn,
		[routes.securebackuppage]: LogIn,
		[routes.settingspage]: LogIn,
		[routes.restorewalletpage]: LogIn,
		[routes.currencypage]: LogIn,
		[routes.assetspage]: LogIn,
		[routes.createassetpage]: LogIn,
		[routes.issueassetpage]: LogIn
	};
}
else{

	export default {
		// [routes.homepage]: Homepage,
		[routes.dashboardpage]: Dashboard,
		[routes.welcomepage]: Welcome,
		[routes.loginpage]: LogIn,
		[routes.portfoliopage]: Portfolio,
		[routes.addcurrencypage]: AddCurrency,
		[routes.walletspage]: Wallets,
		[routes.historypage]: History,
		[routes.securebackuppage]: SecureBackup,
		[routes.settingspage]: Setting,
		[routes.restorewalletpage]: RestoreWallet,
		[routes.currencypage]: Currency,
		[routes.assetspage]: Assets,
		[routes.createassetpage]: CreateAsset,
		[routes.issueassetpage]: IssueAsset
	};
}
*/

export default {
	// [routes.homepage]: Homepage,
	[routes.dashboardpage]: (localStorage.getItem('mnemonic') === null || sessionStorage.getItem("sess_pass") === null) ? LogIn : Dashboard,
	[routes.welcomepage]: (localStorage.getItem('mnemonic') !== null || sessionStorage.getItem("sess_pass") !== null) ? LogIn : Welcome,
	[routes.loginpage]: LogIn,
	[routes.portfoliopage]: (localStorage.getItem('mnemonic') === null || sessionStorage.getItem("sess_pass") === null) ? LogIn : Portfolio,
	[routes.addcurrencypage]: (localStorage.getItem('mnemonic') === null || sessionStorage.getItem("sess_pass") === null) ? LogIn : AddCurrency,
	[routes.walletspage]: (localStorage.getItem('mnemonic') === null || sessionStorage.getItem("sess_pass") === null) ? LogIn : Wallets,
	[routes.historypage]: (localStorage.getItem('mnemonic') === null || sessionStorage.getItem("sess_pass") === null) ? LogIn : History,
	[routes.securebackuppage]: (localStorage.getItem('mnemonic') === null || sessionStorage.getItem("sess_pass") === null) ? LogIn : SecureBackup,
	[routes.settingspage]: (localStorage.getItem('mnemonic') === null || sessionStorage.getItem("sess_pass") === null) ? LogIn : Setting,
	[routes.restorewalletpage]: (localStorage.getItem('mnemonic') === null || sessionStorage.getItem("sess_pass") === null) ? LogIn : RestoreWallet,
	[routes.currencypage]: (localStorage.getItem('mnemonic') === null || sessionStorage.getItem("sess_pass") === null) ? LogIn : Currency,
	[routes.assetspage]: (localStorage.getItem('mnemonic') === null || sessionStorage.getItem("sess_pass") === null) ? LogIn : Assets,
	[routes.createassetpage]: (localStorage.getItem('mnemonic') === null || sessionStorage.getItem("sess_pass") === null) ? LogIn : CreateAsset,
	[routes.issueassetpage]: (localStorage.getItem('mnemonic') === null || sessionStorage.getItem("sess_pass") === null) ? LogIn : IssueAsset,
	[routes.bridgepage]: BridgePage,
	[routes.bridgereprocesspage]: BridgeReprocessPage,
	[routes.ayadaopage]: AyaDaoPage,
};