import React, { useState } from "react";
import { connect } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { Button, FormControl, Modal } from 'react-bootstrap'
import QRCode from 'qrcode.react';
import { aya_omni_simple_send } from '../../../helpers/ayautils';
import NotificationModal from "../../Modals/Notification/Notification";
import { assets_info } from "../../../helpers/assets_info";
import AssetIssueDetails from '../AssetComponent/issueasset';
import { decrypt } from "../../../helpers/cryptoutils";
import AssetTransactionList from './transactions';


const MainComponent = (props) => {

    const { asset_data  , coin_data } = props;
    const [send, setSend] = useState(false);
    const [send_amount, setSend_amount] = useState(asset_data.value/100000000);
    const [send_address, setSend_address] = useState(null);

    const [show, setShow] = useState([false,'static']);
    const [show_status, setShow_status] = useState(['Recovering Keys','Recovering Keys']);
    const handleClose = () => setShow([false,'static']);
    const handleShow = () => setShow([true,'static']);


    const [wpass, setwpass] = useState(sessionStorage.getItem("sess_pass"));
    const [showp, setshowp] = useState(false);
    const handleToggle = () => {
        setSend(!send);
    };

    const send_tx = async() =>{

        setshowp(false);
        if(wpass !== null )
        {
            setShow_status(['Creating and Sending Transaction','Creating and Sending Transaction']);
            handleShow();
            try{
                
                const tx_stats = await aya_omni_simple_send(asset_data.id, send_amount,localStorage.getItem("aya_address"),decrypt(localStorage.getItem("aya_key"),wpass) , send_address);
                if(tx_stats[0] !== true){
                    setShow_status(['Error','Error: ' + tx_stats[1]]);
                }
                else{
                    const stat = tx_stats[1] + ';' + send_amount + ';' + send_address ;
                    setShow_status(['Success',stat]);
                }
            }
            catch(e){
                setShow_status(['Error','API Error Occured Please try again later']);
                setwpass(null);
            }
            setShow([true,true]);
            localStorage.setItem('f_update','true');
        }
        else{
            setshowp(true);
        }
        
    };

    const ps_verify = <a href="https://aryacoin.medium.com/verifying-aryacoin-omnilayer-asset-on-xerxes-wallet-bd368ea0c77d">No (Get verified and add logo)</a> ;
    
    const [value, onChange] = useState(new Date());
    //
    return (
        <>
            <div className={`zl_chart_component ${send ? "active" : ""}`}>
                <div className="zl_all_page_comman_content">
                    <div className="zl_chart_box_heading_date">
                        <h2 className="zl_chart_box_heading">{asset_data.propertyinfo.propertyname}</h2>
                    </div>
                    <div className="zl_wallet_chart_bottom_content">
                        <div className="zl_all_page_comman_total_price">
                            <p className="zl_all_page_total_price_heading">Property ID   : {asset_data.id}</p>
                            <p className="zl_all_page_total_price_heading">Issuer Address: <a href={'https://omniexplorer.aryacoin.io/address/'+asset_data.propertyinfo.issuer} target="_blank">{asset_data.propertyinfo.issuer}</a></p>
                            <p className="zl_all_page_total_price_heading">Issuance Type : {asset_data.propertyinfo.managedissuance ? 'Managed' : 'Fixed'}</p>
                            <p className="zl_all_page_total_price_heading">Property URL  : {asset_data.propertyinfo.url}</p>
                            <p className="zl_all_page_total_price_heading" style={{width: '343px'}}>Description   : {assets_info.filter((itemx) => {return itemx.id == asset_data.id}).length > 0 ? assets_info[0].description : asset_data.propertyinfo.data}</p>
                            <p className="zl_all_page_total_price_heading">Verified      : {assets_info.filter((itemx) => {return itemx.id == asset_data.id}).length > 0 ? 'YES' : (asset_data.propertyinfo.issuer === coin_data[0].data.addrStr ? ps_verify : 'NO')}</p>
                        </div>
                        <div className="zl_all_page_comman_total_price">
                            <p className="zl_all_page_total_price_heading">Total Balance</p>
                            <h2 className="zl_all_page_total_price_text">{asset_data.value/100000000}</h2>
                        </div>
                        <div className="zl_wallet_chart_send_recive_btn">
                            <Button className="zl_wallet_chart_send_btn" onClick={handleToggle}>
                                <svg width="15" height="15" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.60609 3.60609L2.69695 4.51523C2.36222 4.84996 1.81951 4.84996 1.48477 4.51523C1.15004 4.18049 1.15004 3.63778 1.48477 3.30305L2.39391 2.39391L0 0H6V6L3.60609 3.60609Z" fill="#252F47" />
                                </svg>
                                Send
                            </Button>
                            <Button className="zl_wallet_chart_recive_btn" onClick={handleToggle}>
                                <svg width="15" height="15" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.60609 3.60609L2.69695 4.51523C2.36222 4.84996 1.81951 4.84996 1.48477 4.51523C1.15004 4.18049 1.15004 3.63778 1.48477 3.30305L2.39391 2.39391L0 0H6V6L3.60609 3.60609Z" fill="#252F47" />
                                </svg>
                                Receive
                            </Button>
                        </div>
                        
                    </div>
                    <br />
                    <AssetIssueDetails asset_data={asset_data} />
                    
                </div>
                <div className="zl_send_recive_content">
                    <div className="zl_send_recive_content_row">
                        <div className="zl_send_recive_content_column">
                            <div className="zl_send_recive_inner_content">
                                <h3 className="zl_send_recive_heading">
                                    <svg width="15" height="15" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3.60609 3.60609L2.69695 4.51523C2.36222 4.84996 1.81951 4.84996 1.48477 4.51523C1.15004 4.18049 1.15004 3.63778 1.48477 3.30305L2.39391 2.39391L0 0H6V6L3.60609 3.60609Z" fill="#53B9EA" />
                                    </svg>
                                    Send
                                </h3>
                                <div className="zl_send_qr_address">
                                    <FormControl
                                        placeholder="Insert address"
                                        onChange={(event) => {setSend_address(event.target.value)}}
                                    />
                                    <QRCode
                                        value={coin_data[0].data.addrStr}
                                        bgColor={"#3D476A"}
                                        fgColor={"#CAD3F2"}
                                        size={32}
                                        className="zl_dark_theme_qrcode"
                                    />
                                    <QRCode
                                        value={coin_data[0].data.addrStr}
                                        bgColor={"#EFF0F2"}
                                        fgColor={"#3D476A"}
                                        size={32}
                                        className="zl_light_theme_qrcode"
                                    />
                                </div>
                                <div className="zl_send_currency_input_content">
                                    <FormControl
                                        type="number"
                                        className="zl_send_currency_input"
                                        value={send_amount}
                                        onChange={(event) => { 
                                            if(Number(event.target.value) < asset_data.value/100000000) {setSend_amount(event.target.value)}  else{setSend_amount(asset_data.value/100000000)} }}
                                    />
                                    <div className="zl_send_currency_input_btns">
                                        <Button onClick={(event) => {setSend_amount(send_amount/4)}}>1/4</Button>
                                        <Button onClick={(event) => {setSend_amount(send_amount/2)}}>Half</Button>
                                        <Button onClick={(event) => {setSend_amount(asset_data.value/100000000)}}>All</Button>
                                    </div>
                                </div>
                                <div className="zl_send_currency_text_type">
                                    <h3 className="zl_send_currency_type"></h3>
                                </div>
                                <div className="zl_send_currency_btn_text">
                                    <Button className="zl_send_currency_btn" id="send_but" onClick={send_tx}>
                                        Send
                                    </Button>
                                    <div className="zl_send_currency_text">
                                        <p>Network Fee<span>0.000040001 AYA</span></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="zl_send_recive_content_column">
                            <div className="zl_send_recive_inner_content">
                                <h3 className="zl_send_recive_heading zl_recive_heading">
                                    <svg width="15" height="15" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3.60609 3.60609L2.69695 4.51523C2.36222 4.84996 1.81951 4.84996 1.48477 4.51523C1.15004 4.18049 1.15004 3.63778 1.48477 3.30305L2.39391 2.39391L0 0H6V6L3.60609 3.60609Z" fill="#53B9EA" />
                                    </svg>
                                    Receive
                                </h3>
                                <div className="zl_recive_address_content">
                                    <p className="zl_recive_address_heading">Address</p>
                                    <div className="zl_recive_copy_address_content">
                                        <Button onClick={() => navigator.clipboard.writeText(coin_data[0].data.addrStr)}>
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1.48116 0H12.5365C13.3244 0 13.9653 0.641 13.9653 1.42887V4.78252H12.661V1.42887C12.661 1.36022 12.6051 1.30435 12.5365 1.30435H1.48116C1.4125 1.30435 1.35663 1.36022 1.35663 1.42887V12.4842C1.35663 12.5529 1.4125 12.6087 1.48116 12.6087H4.73024V13.9131H1.48116C0.693287 13.9131 0.0522861 13.2721 0.0522861 12.4842V1.42887C0.0523291 0.641 0.693287 0 1.48116 0Z" fill="#CAD3F2" />
                                                <path d="M7.46358 6.08691H18.5188C19.3068 6.08691 19.9478 6.72791 19.9478 7.51583V18.5711C19.9477 19.3591 19.3068 20.0001 18.5188 20.0001H7.46354C6.67562 20.0001 6.03463 19.3591 6.03463 18.5712V7.51583C6.03458 6.72791 6.67567 6.08691 7.46358 6.08691ZM7.46349 18.6957H18.5188C18.5875 18.6957 18.6434 18.6398 18.6434 18.5712V7.51583C18.6434 7.44713 18.5875 7.39126 18.5188 7.39126H7.46354C7.39484 7.39126 7.33897 7.44713 7.33897 7.51583V18.5712H7.33893C7.33893 18.6398 7.39484 18.6957 7.46349 18.6957Z" fill="#CAD3F2" />
                                            </svg>
                                        </Button>
                                        <p>{coin_data[0].data.addrStr}</p>
                                    </div>
                                    <div className="zl_recive_address_qr_code">
                                        <QRCode
                                            value={coin_data[0].data.addrStr}
                                            bgColor={"transparent"}
                                            fgColor={"#CAD3F2"}
                                            size={166}
                                            className="zl_dark_theme_qrcode"
                                        />
                                        <QRCode
                                            value={coin_data[0].data.addrStr}
                                            bgColor={"transparent"}
                                            fgColor={"#3D476A"}
                                            size={166}
                                            className="zl_light_theme_qrcode"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <NotificationModal show_status={show_status} show={show} type={'omni_send'}/>    
                        <Modal style={{'textAlign':'center'}} centered show={showp} onHide={() => setshowp(false)} backdrop={true} keyboard={false} >
                                    <Modal.Header closeButton>
                                    <Modal.Title>Enter Password</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div className="zl_login_input_content position-relative">
                                            <input type="password" className="zl_login_input" id={`pass_input`} onChange={(event) => {setwpass(event.target.value)}} placeholder='**********'  />
                                        </div>
                                        <br />
                                        <div className="zl_login_btn">
                                            <Button id="pass_btn" onClick={ () => {send_tx();} } className="zl_send_currency_btn btn btn-primary"> {'Send Transaction' }</Button>
                                        </div>
                                    </Modal.Body>
                        </Modal>  
                    </div>
                </div>
                <div className="zl_transaction_list">
                        <h3 className="zl_transaction_list_main_heading">
                            Transaction
                        </h3>
                        <AssetTransactionList id={asset_data.id} />
                </div>
            </div>
        </>
    );
    
}

export default connect(null, null)(MainComponent);
