import axios from 'axios';
import ayacore from 'ayacore-lib';

function gen_ayakey(mnemonic_code){
    
    var Mnemonic = require('ayacore-mnemonic');
    var code = new Mnemonic(mnemonic_code);
    var xpriv = code.toHDPrivateKey();
    var pvtkey = new ayacore.HDPrivateKey(xpriv).privateKey.toString();
    var privkey =  new ayacore.PrivateKey.fromWIF(pvtkey);
    var aya_publicKey = privkey.toPublicKey();
    var aya_address = aya_publicKey.toAddress();
    localStorage.setItem('aya_address', aya_address.toString());
    //localStorage.setItem('aya_key', privkey);    
    return privkey;
    
}

async function getUnspentOutputs (address) {
    const url = 'https://ayablockexplorer.com/api/v2/utxo/' + address;
    const resp = await axios.get(url);
    const data = resp.data;
    return data;
}

const convertUTXO = (utxo) => (
    {
      txId: utxo.txid,
      outputIndex: utxo.vout,
      address: utxo.address,
      script: utxo.script,
      satoshis: Number(utxo.value),
    }
)


async function getUTXOs (address) {

    let utxos = await getUnspentOutputs(address);
    
    //check UTXO's
    //utxos = utxos.filter(utxo => utxo.confirmations > 0)
    utxos = utxos.filter(utxo => Number(utxo.value) > 1000)
    if (utxos.length === 0){
        console.log('No Valid Output');
    }
    else{
        console.log("getUTXOs" + JSON.stringify(utxos));
        //add utxo   script in json array
        for(var utxo_data in utxos){
            const url = 'https://ayablockexplorer.com/api/v2/tx/' + utxos[utxo_data].txid;
            const resp = await axios.get(url);
            const data = resp.data;
            utxos[utxo_data].script = data.vout[utxos[utxo_data].vout].hex;
            utxos[utxo_data].address = address;
        }
        console.log("getUTXOs2" + JSON.stringify(utxos));
        utxos = utxos.map(convertUTXO);
        console.log("getUTXOs3" + JSON.stringify(utxos));
        return utxos;

    }
    

}

async function checkUTXOs( utxos, address) {

    //utxos = utxos.filter(utxo => utxo.confirmations > 0)
    utxos = utxos.filter(utxo => Number(utxo.value) > 1)
    if (utxos.length === 0) throw Error(`No spendable outputs were found, please fund the address or wait for transactions to confirm - address: ${address}`)
}

async function create_aya_tx (from_address ,from_key , to_address , amount , aya_data = "false", fees= 2000){
    
    try{
        //send amounts in satoshis
        if(aya_data === "false"){
            amount = parseInt(amount * 100000000);
            if(amount < fees){
                return ([false,'Amount too low']);
            }
        }
        
        let utxos = await getUTXOs(from_address);
        let spendable_balance = 0 ;
        let utxo_use = 0;
        let use_utxos = []; 
        //caclulate whole utxo output
        for(var utxo_data in utxos){
            spendable_balance = spendable_balance + utxos[utxo_data].satoshis;
            use_utxos.push(utxos[utxo_data]);
            if (spendable_balance > (amount + fees)){
                //get utxo's required for amount
                utxo_use = utxo_data;
                break; 
            }
        }
        console.log("Found breakpoint Array:" + JSON.stringify(use_utxos));
        console.log("Sepndable balance:" + spendable_balance);
        console.log("Wanted balance:" + amount);
        if((amount+fees) <= spendable_balance){
            
            console.log("utxos:", use_utxos);
            if(aya_data === "false"){
                const tx = new ayacore.Transaction()
                .from(utxos)
                .to(to_address, amount)
                .change(from_address)
                .fee(fees)
                .sign(new ayacore.PrivateKey(from_key));

                const txHex = tx.serialize();
                const url = 'https://ayablockexplorer.com/api/v2/sendtx/' + txHex;
                const resp = await axios.get(url);
                const data = resp.data;
                //console.log('TXDONE' + data);

                localStorage.setItem('f_update','true');
                return ([true,data.result]);
            }
            else{   
                const tx = new ayacore.Transaction()
                .from(utxos);

                const scc = ayacore.Script().add('OP_RETURN').add(new Buffer(aya_data,'hex')) ;

                tx.addOutput(new ayacore.Transaction.Output ({
                    script: scc ,
                    satoshis: 0
                }));

                if(to_address === from_address){

                    tx
                    .change(from_address)
                    .fee(2000)
                    .sign(from_key);
                    
                }
                else{

                    tx
                    .to(to_address, amount)
                    .change(from_address)
                    .fee(2000)
                    .sign(from_key);
                }
                


                const txHex = tx.serialize();
                console.log("tx_Omni:", txHex);

                const url = 'https://ayablockexplorer.com/api/v2/sendtx/' + txHex;
                const resp = await axios.get(url);
                const data = resp.data;

                localStorage.setItem('f_update','true');
                return ([true,data.result]);
                
            }
            
            
        }
        else{
            //not enough spendable balance
            console.log("Not enough balance");
            console.log("Not enough balance");
            return [false,"Insufficient Aryacoin(AYA) Balance"];
        }
    }   
    catch(ee){
        console.log(ee);
        return [false,"Please try again later"];
        
    }
    


    

}

async function get_omni_data(aya_address){

    var i = 1;
    if(localStorage.getItem('omni_page') === null){
        var url = 'https://omniapi.aryacoin.io/v1/transaction/address/1';
        var resp = await axios.post(url,'addr='+aya_address);
        var data = resp.data.transactions;
        var ps_return = resp.data.transactions;
        var pages = resp.data.pages;
        let page = 2;
        
        if(pages > 1){
            while(pages+1 > page){
                url = 'https://omniapi.aryacoin.io/v1/transaction/address/'+page;
                resp = await axios.post(url,'addr='+aya_address);
                data = resp.data.transactions;
                ps_return = ps_return.concat(data);
                page = page +1;
                i = i+1;
                if(i > 3){
                    break;
                }
            }
        }
        localStorage.setItem('omni_page',page);
        return ps_return;
    }
    else{
        let page = Number(localStorage.getItem('omni_page'));
        var url = 'https://omniapi.aryacoin.io/v1/transaction/address/'+page;
        var resp = await axios.post(url,'addr='+aya_address);
        var data = resp.data.transactions;
        var ps_return = resp.data.transactions;
        var pages = resp.data.pages;
        if(pages > page){
            while(pages+1 > page){
                url = 'https://omniapi.aryacoin.io/v1/transaction/address/'+page;
                resp = await axios.post(url,'addr='+aya_address);
                data = resp.data.transactions;
                ps_return = ps_return.concat(data);
                page = page +1;
                i = i+1;
                if(i > 3){
                    break;
                }
            }
            localStorage.setItem('omni_page',page);
        }
        //return ps_return;
        return JSON.parse(localStorage.getItem('aya_omni_data')).concat(ps_return);
    }


    
    
    

}

async function get_omni_balance(aya_address){

    const url = 'https://omniapi.aryacoin.io/v1/address/addr';
    const resp = await axios.post(url,'addr='+aya_address);
    const data = resp.data;
    return data;
    
}


async function aya_omni_simple_send(property_id,property_amount,from_address,from_key,to_address){

    const simple_send = [
        "6f6d6e69", // omni 4bytes
        "0000", //Version: 0 : 2bytes
        "0000", // Type : 0 : 2bytes
        Number(property_id).toString(16).padStart(8,'0') , // PropertyID : 4bytes
        (property_amount*100000000).toString(16).padStart(16,'0') // Amount: 8Bytes
    ].join('');

    const ps_st = await create_aya_tx(from_address,from_key,to_address,20001,simple_send);
    return ps_st;
}


async function aya_omni_issue_fixed(from_address,from_key,prop_name,property_amount,prop_url="\0\0",prop_data="\0\0",prop_category="\0\0",prop_sub="\0\0"){

    const issue_fixed = [
        "6f6d6e69", // omni 4bytes
        "0000", //Version: 0 : 2bytes
        "0032", // TX Type : 32 for fixed , 36 for managed : 2bytes
        "01", // Ecosystem : 1 : 1 byte
        "0002", //Property Type : 2 for divisible token , 1 for non divisible 2 bytes
        "0000", // Previous Property ID always 0000 //2bytes
        prop_category.split("").map(c => c.charCodeAt(0).toString(16).padStart(2, "0")).join(""), //Property Category either 0000 //2bytes
        prop_sub.split("").map(c => c.charCodeAt(0).toString(16).padStart(2, "0")).join(""), //Property SUBCategory either 0000 //2bytes
        prop_name.split("").map(c => c.charCodeAt(0).toString(16).padStart(2, "0")).join(""), //Property NAME  //10bytes 
        prop_url.split("").map(c => c.charCodeAt(0).toString(16).padStart(2, "0")).join(""), //Property URL
        prop_data.split("").map(c => c.charCodeAt(0).toString(16).padStart(2, "0")).join(""), //Property DATA
        (property_amount*100000000).toString(16).padStart(16,'0') // Amount: 8Bytes
    ].join('');
    
    if(issue_fixed.length > 190){
        //Data too long
        return ([false,'Data Too Long: Please Shorten the description or the URL for the Asset']);;
    }
    const ps_st = await create_aya_tx(from_address,from_key,from_address,20001,issue_fixed);
    return ps_st;

}


async function aya_omni_issue_managed(from_address,from_key,prop_name,property_amount,prop_url="\0\0",prop_data="\0\0",prop_category="\0\0",prop_sub="\0\0"){

    
    const issue_managed = [ 
        "6f6d6e69", // omni 4bytes
        "0000", //Version: 0 : 2bytes
        "0036", // TX Type : 32 for fixed , 36 for managed : 2bytes
        "01", // Ecosystem : 1 : 1 byte
        "0002", //Property Type : 2 for divisible token , 1 for non divisible 2 bytes, 5 for NFT
        "0000", // Previous Property ID always 0000
        prop_category.split("").map(c => c.charCodeAt(0).toString(16).padStart(2, "0")).join(""), //Property Category either 0000 
        prop_sub.split("").map(c => c.charCodeAt(0).toString(16).padStart(2, "0")).join(""), //Property SUBCategory either 0000 
        prop_name.split("").map(c => c.charCodeAt(0).toString(16).padStart(2, "0")).join(""), //Property NAME
        prop_url.split("").map(c => c.charCodeAt(0).toString(16).padStart(2, "0")).join(""), //Property URL
        prop_data.split("").map(c => c.charCodeAt(0).toString(16).padStart(2, "0")).join("") //Property DATA
        
    ].join('');

    if(issue_managed.length > 190){
        //Data too long
        return ([false,'Data Too Long: Please Shorten the description or the URL for the Asset']);;
    }
    const ps_st = await create_aya_tx(from_address,from_key,from_address,20001,issue_managed);
    return ps_st;
}


async function aya_omni_create_NFT(from_address,from_key,prop_name,property_amount,prop_url="\0\0",prop_data="\0\0",prop_category="\0\0",prop_sub="\0\0"){

    
    const issue_managed = [ 
        "6f6d6e69", // omni 4bytes
        "0000", //Version: 0 : 2bytes
        "0036", // TX Type : 32 for fixed , 36 for managed : 2bytes
        "01", // Ecosystem : 1 : 1 byte
        "0005", //Property Type : 2 for divisible token , 1 for non divisible 2 bytes, 5 for NFT
        "0000", // Previous Property ID always 0000
        prop_category.split("").map(c => c.charCodeAt(0).toString(16).padStart(2, "0")).join(""), //Property Category either 0000 
        prop_sub.split("").map(c => c.charCodeAt(0).toString(16).padStart(2, "0")).join(""), //Property SUBCategory either 0000 
        prop_name.split("").map(c => c.charCodeAt(0).toString(16).padStart(2, "0")).join(""), //Property NAME
        prop_url.split("").map(c => c.charCodeAt(0).toString(16).padStart(2, "0")).join(""), //Property URL
        prop_data.split("").map(c => c.charCodeAt(0).toString(16).padStart(2, "0")).join("") //Property DATA
        
    ].join('');

    if(issue_managed.length > 190){
        //Data too long
        return ([false,'Data Too Long: Please Shorten the description or the URL for the Asset']);;
    }
    const ps_st = await create_aya_tx(from_address,from_key,from_address,20001,issue_managed);
    return ps_st;
}

async function aya_omni_managed_grant(from_address,from_key,to_address,property_amount,property_id,memo){

    console.log('MEMO:' + memo);
    if(memo === null){
        const managed_grant = [
            "6f6d6e69", // omni 4bytes
            "0000", //Version: 0 : 2bytes
            "0037", // Type : 55 : 2bytes
            Number(property_id).toString(16).padStart(8,'0') , // PropertyID : 4bytes
            (property_amount*100000000).toString(16).padStart(16,'0') // Amount: 8Bytes
        ].join('');
    
        
        const ps_st = await create_aya_tx(from_address,from_key,to_address,20001,managed_grant);
        return ps_st;
        //return managed_grant;
    }
    else{
        const managed_grant = [
            "6f6d6e69", // omni 4bytes
            "0000", //Version: 0 : 2bytes
            "0037", // Type : 55 : 2bytes
            Number(property_id).toString(16).padStart(8,'0') , // PropertyID : 4bytes
            (property_amount).toString(16).padStart(16,'0'), // Amount: 8Bytes
            memo.split("").map(c => c.charCodeAt(0).toString(16).padStart(2, "0")).join("")
        ].join('');
    
        console.log('NFT ISSUe'+managed_grant);
        const ps_st = await create_aya_tx(from_address,from_key,to_address,20001,managed_grant);
        return ps_st;
        //return managed_grant;
    }
    
}

async function omni_send_nft(from_address,from_key,to_address,property_id,token_id){
    const simple_send_nft = [
        "6f6d6e69", // omni 4bytes
        "0000", //Version: 0 : 2bytes
        "0005", // Type : 0 : 2bytes
        Number(property_id).toString(16).padStart(8,'0') , // PropertyID : 4bytes
        (token_id).toString(16).padStart(16,'0') // Amount: 8Bytes
    ].join('');

    const ps_st = await create_aya_tx(from_address,from_key,to_address,20001,simple_send_nft);
    return ps_st;
}

async function aya_omni_managed_revoke(from_address,from_key,to_address,property_amount,property_id,memo="\0\0"){

    const managed_revoke = [
        "6f6d6e69", // omni 4bytes
        "0000", //Version: 0 : 2bytes
        "0038", // Type : 55 : 2bytes
        Number(property_id).toString(16).padStart(8,'0') , // PropertyID : 4bytes
        (property_amount*100000000).toString(16).padStart(16,'0'), // Amount: 8Bytes
        memo.split("").map(c => c.charCodeAt(0).toString(16).padStart(2, "0")).join("")
    ].join('')

    
    const ps_st = await create_aya_tx(from_address,from_key,to_address,20001,managed_revoke);
    return ps_st;

}

export {gen_ayakey, create_aya_tx, get_omni_data, get_omni_balance, aya_omni_managed_revoke, aya_omni_managed_grant, aya_omni_issue_managed, aya_omni_issue_fixed, aya_omni_simple_send, aya_omni_create_NFT,omni_send_nft};