import React, { useState } from "react";
import { connect } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import Chart from "react-apexcharts";
import { Button, FormControl } from 'react-bootstrap'
import QRCode from 'qrcode.react';
import { Link } from 'react-router-dom';
import AllTransactionList from "../TransactionList/AllTransactionList";
import { cuurency } from "../../../helpers/currency_info";
import { getchart, decrypt } from '../../../helpers/cryptoutils';
import { create_aya_tx } from '../../../helpers/ayautils';
import { create_btc_tx } from '../../../helpers/btcutils';
import { create_dash_tx } from '../../../helpers/dashutils';
import NotificationModal from '../../Modals/Notification/Notification';
import { Modal } from 'react-bootstrap'


const MainComponent = (props) => {

    const { id, coin_data } = props;
    // date picker
    const [dateRange, setDateRange] = useState([null, null]);

    const [endDate, set_endDate] = useState(new Date().getTime());
    const [startDate, set_startDate] = useState(new Date(endDate - 2592000000).getTime());
    //const [endDate, set_endDate] = useState(null);
    //const [startDate, set_startDate] = useState(null);

    // send btn
    const [send, setSend] = useState(false);
    const [send_amount, setSend_amount] = useState(coin_data[id].data.balance);
    const [send_address, setSend_address] = useState(null);

    const handleToggle = () => {
        setSend(!send);
    };

    const [price_data, set_price_data] = useState(null);

    const [show, setShow] = useState([false,'static']);
    const [show_status, setShow_status] = useState(['Recovering Keys','Recovering Keys']);
    const handleClose = () => setShow([false,'static']);
    const handleShow = () => setShow([true,'static']);

    const [wpass, setwpass] = useState(sessionStorage.getItem("sess_pass"));
    const [showp, setshowp] = useState(false);

    const ntive_icon = <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.60609 2.39391L2.69695 1.48477C2.36222 1.15004 1.81951 1.15004 1.48477 1.48477C1.15004 1.81951 1.15004 2.36222 1.48477 2.69695L2.39391 3.60609L0 6H6V0L3.60609 2.39391Z" fill="#E3507A" /></svg>;
    const ptive_icon = <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.60609 3.60609L2.69695 4.51523C2.36222 4.84996 1.81951 4.84996 1.48477 4.51523C1.15004 4.18049 1.15004 3.63778 1.48477 3.30305L2.39391 2.39391L0 0H6V6L3.60609 3.60609Z" fill="#50E2C2" /></svg>;

    const send_tx = async() =>{

        setshowp(false);
        if(wpass !== null ){
            setShow_status(['Creating Transaction','Creating and Broadcasting Transaction']);
            handleShow();
            
            try{
                if(id === 0 ){
                    const tx_stats = await  create_aya_tx(localStorage.getItem('aya_address'),decrypt(localStorage.getItem("aya_key"),wpass), send_address, send_amount );
                    if(tx_stats[0] !== true){
                        setShow_status(['Error','Error: ' + tx_stats[1]]);
                    }
                    else{
                        const stat = tx_stats[1] + ';' + send_amount + ';' + send_address + ';' + 'https://ayablockexplorer.com/tx/'+tx_stats[1];
                        setShow_status(['Success',stat]);
                    }
                }
                else if(id === 1 ){
                    const tx_stats = await  create_btc_tx(localStorage.getItem('btc_address'),decrypt(localStorage.getItem("btc_key"),wpass), send_address, send_amount );
                    if(tx_stats[0] !== true){
                        setShow_status(['Error','Error: ' + tx_stats[1]]);
                    }
                    else{
                        const stat = tx_stats[1] + ';' + send_amount + ';' + send_address + ';' + 'https://bitcoinblockexplorer.com/tx/'+tx_stats[1];
                        setShow_status(['Success',stat]);
                    }
                }
                else if(id === 2 ){
                    const tx_stats = await  create_dash_tx(localStorage.getItem('dash_address'),decrypt(localStorage.getItem("dash_key"),wpass), send_address, send_amount );
                    if(tx_stats[0] !== true){
                        setShow_status(['Error','Error: ' + tx_stats[1]]);
                    }
                    else{
                        const stat = tx_stats[1] + ';' + send_amount + ';' + send_address + ';' + 'https://dashblockexplorer.com/tx/'+tx_stats[1];
                        setShow_status(['Success',stat]);
                    }
                }
                else{
                    console.log('Unknow Currency');
                }
            }
            catch(e){
                setShow_status(['Error','API Error Occured Please try again later']);
                setwpass(null);
            }
            

            setShow([true,true]);
        }
        else{
            setshowp(true);
        }
        
        
    }
    const get_price_data = async() =>{
        try {
            const p_data = await getchart(cuurency[id].full_name.toLowerCase(),'usd',startDate,endDate);
            set_price_data(p_data,'usd',startDate,endDate);
        } catch (e) {
            console.log(e);
        }
    }



    const [value, onChange] = useState(new Date());
    const options = {
        chart: {
            zoom: {
                enabled: true
            },
            stacked: false,
        },
        xaxis: {
            categories: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
            borderColor: '#999',
            yAxisIndex: 0,
            type: 'datetime',
            min: startDate,
            tickAmount: 6,
            x: startDate,
            label: {
                show: true,
                text: 'Support',
                style: {
                    colors: ['#fff'],
                    background: '#00E396'
                }
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            width: 2,
            curve: "smooth",
            colors: [cuurency[id].stroke2],
        },
        fill: {
            type: 'gradient',
            colors: [cuurency[id].stroke2, 'rgba(45, 55, 83, 0)'],
            gradient: {
                shadeIntensity: 1,
                opacityFrom: 0.7,
                opacityTo: 0.2,
                stops: [0, 90, 100]
            }
        },
        series: [
            {
                name: "USD Price",
                type: 'datetime',
                data: price_data
            }
        ]
    }


    //if(startDate === null){
    //    set_endDate(new Date().getTime());
    //    set_startDate(new Date(endDate - 2592000000).getTime());
    //}

    if(price_data === null || coin_data === null){
        get_price_data();
        return (
            <>
                <div className={`zl_chart_component ${send ? "active" : ""}`}>
                    <div className="zl_all_page_comman_content">
                        <div className="zl_chart_box_heading_date">
                            <h2 className="zl_chart_box_heading">{cuurency[id].name}</h2>
                            <div className="position-relative">
                                
                            </div>
                        </div>
                        <div className="zl_dashboard_chart">
                            
                        </div>
                        <div className="zl_wallet_chart_bottom_content">
                            <div className="zl_all_page_comman_total_price">
                                <p className="zl_all_page_total_price_heading">Total Balance</p>
                                <h2 className="zl_all_page_total_price_text">{coin_data[id].data.balance}</h2>
                                <span className="zl_add_currency_down_price">
                                    <span className={coin_data[id].price.price_change_percentage_24h > 0 ? "" : "zl_add_currency_down_price"}>
                                        {coin_data[id].price.price_change_percentage_24h > 0 ? ptive_icon : ntive_icon}
                                        {(coin_data[id].price.price_change_percentage_24h).toFixed(2)}%
                                    </span>
                                </span>
                            </div>
                            <div className="zl_wallet_chart_send_recive_btn">
                                <Button className="zl_wallet_chart_send_btn" onClick={handleToggle}>
                                    <svg width="15" height="15" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3.60609 3.60609L2.69695 4.51523C2.36222 4.84996 1.81951 4.84996 1.48477 4.51523C1.15004 4.18049 1.15004 3.63778 1.48477 3.30305L2.39391 2.39391L0 0H6V6L3.60609 3.60609Z" fill="#252F47" />
                                    </svg>
                                    Send
                                </Button>
                                <Button className="zl_wallet_chart_recive_btn" onClick={handleToggle}>
                                    <svg width="15" height="15" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3.60609 3.60609L2.69695 4.51523C2.36222 4.84996 1.81951 4.84996 1.48477 4.51523C1.15004 4.18049 1.15004 3.63778 1.48477 3.30305L2.39391 2.39391L0 0H6V6L3.60609 3.60609Z" fill="#252F47" />
                                    </svg>
                                    Receive
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className="zl_send_recive_content">
                        <div className="zl_send_recive_content_row">
                            <div className="zl_send_recive_content_column">
                                <div className="zl_send_recive_inner_content">
                                    <h3 className="zl_send_recive_heading">
                                        <svg width="15" height="15" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M3.60609 3.60609L2.69695 4.51523C2.36222 4.84996 1.81951 4.84996 1.48477 4.51523C1.15004 4.18049 1.15004 3.63778 1.48477 3.30305L2.39391 2.39391L0 0H6V6L3.60609 3.60609Z" fill="#53B9EA" />
                                        </svg>
                                        Send
                                    </h3>
                                    <div className="zl_send_qr_address">
                                        <FormControl
                                            placeholder="Insert address"
                                            onChange={(event) => {setSend_address(event.target.value)}}
                                        />
                                        <QRCode
                                            value={coin_data[id].data.addrStr}
                                            bgColor={"#3D476A"}
                                            fgColor={"#CAD3F2"}
                                            size={32}
                                            className="zl_dark_theme_qrcode"
                                        />
                                        <QRCode
                                            value={coin_data[id].data.addrStr}
                                            bgColor={"#EFF0F2"}
                                            fgColor={"#3D476A"}
                                            size={32}
                                            className="zl_light_theme_qrcode"
                                        />
                                    </div>
                                    <div className="zl_send_currency_input_content">
                                        <FormControl
                                            type="number"
                                            className="zl_send_currency_input"
                                            value={send_amount}
                                            onChange={(event) => {setSend_amount(event.target.value)}}
                                        />
                                        <div className="zl_send_currency_input_btns">
                                            <Button onClick={(event) => {setSend_amount(send_amount/4)}}>1/4</Button>
                                            <Button onClick={(event) => {setSend_amount(send_amount/2)}}>Half</Button>
                                            <Button onClick={(event) => {setSend_amount(coin_data[id].data.balance)}}>All</Button>
                                        </div>
                                    </div>
                                    <div className="zl_send_currency_text_type">
                                        <h3 className="zl_send_currency_text">{'$' + send_amount * coin_data[id].price.current_price.toFixed(4)}</h3>
                                        <h3 className="zl_send_currency_type">USD</h3>
                                    </div>
                                    <div className="zl_send_currency_btn_text">
                                        <Button className="zl_send_currency_btn" onClick={send_tx}>
                                            Send
                                        </Button>
                                        <div className="zl_send_currency_text">
                                            <p>Network Fee<span>0.00006 </span></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="zl_send_recive_content_column">
                                <div className="zl_send_recive_inner_content">
                                    <h3 className="zl_send_recive_heading zl_recive_heading">
                                        <svg width="15" height="15" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M3.60609 3.60609L2.69695 4.51523C2.36222 4.84996 1.81951 4.84996 1.48477 4.51523C1.15004 4.18049 1.15004 3.63778 1.48477 3.30305L2.39391 2.39391L0 0H6V6L3.60609 3.60609Z" fill="#53B9EA" />
                                        </svg>
                                        Receive
                                    </h3>
                                    <div className="zl_recive_address_content">
                                        <p className="zl_recive_address_heading">Address</p>
                                        <div className="zl_recive_copy_address_content">
                                            <Button onClick={() => navigator.clipboard.writeText(coin_data[id].data.addrStr)}>
                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M1.48116 0H12.5365C13.3244 0 13.9653 0.641 13.9653 1.42887V4.78252H12.661V1.42887C12.661 1.36022 12.6051 1.30435 12.5365 1.30435H1.48116C1.4125 1.30435 1.35663 1.36022 1.35663 1.42887V12.4842C1.35663 12.5529 1.4125 12.6087 1.48116 12.6087H4.73024V13.9131H1.48116C0.693287 13.9131 0.0522861 13.2721 0.0522861 12.4842V1.42887C0.0523291 0.641 0.693287 0 1.48116 0Z" fill="#CAD3F2" />
                                                    <path d="M7.46358 6.08691H18.5188C19.3068 6.08691 19.9478 6.72791 19.9478 7.51583V18.5711C19.9477 19.3591 19.3068 20.0001 18.5188 20.0001H7.46354C6.67562 20.0001 6.03463 19.3591 6.03463 18.5712V7.51583C6.03458 6.72791 6.67567 6.08691 7.46358 6.08691ZM7.46349 18.6957H18.5188C18.5875 18.6957 18.6434 18.6398 18.6434 18.5712V7.51583C18.6434 7.44713 18.5875 7.39126 18.5188 7.39126H7.46354C7.39484 7.39126 7.33897 7.44713 7.33897 7.51583V18.5712H7.33893C7.33893 18.6398 7.39484 18.6957 7.46349 18.6957Z" fill="#CAD3F2" />
                                                </svg>
                                            </Button>
                                            <p>{coin_data[id].data.addrStr}</p>
                                        </div>
                                        <div className="zl_recive_address_qr_code">
                                            <QRCode
                                                value={coin_data[id].data.addrStr}
                                                bgColor={"transparent"}
                                                fgColor={"#CAD3F2"}
                                                size={166}
                                                className="zl_dark_theme_qrcode"
                                            />
                                            <QRCode
                                                value={coin_data[id].data.addrStr}
                                                bgColor={"transparent"}
                                                fgColor={"#3D476A"}
                                                size={166}
                                                className="zl_light_theme_qrcode"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="zl_transaction_list">
                        <h3 className="zl_transaction_list_main_heading">
                            Transaction
                        <Link to={'/history'}>See All</Link>
                        </h3>
                        <AllTransactionList id={id} coin_data={coin_data} limit={10} />
                    </div>
                </div>
            </>
        );
    }
    else{
        return (
            <>
                <div className={`zl_chart_component ${send ? "active" : ""}`}>
                    <div className="zl_all_page_comman_content">
                        <div className="zl_chart_box_heading_date">
                            <h2 className="zl_chart_box_heading">{cuurency[id].name}</h2>
                            <div className="position-relative">
                                
                            </div>
                        </div>
                        <div className="zl_dashboard_chart">
                            <Chart options={options} series={options.series} type="area" height={350} />
                        </div>
                        <div className="zl_wallet_chart_bottom_content">
                            <div className="zl_all_page_comman_total_price">
                                <p className="zl_all_page_total_price_heading">Total Balance</p>
                                <h2 className="zl_all_page_total_price_text">{coin_data[id].data.balance}</h2>
                                <span className={coin_data[id].price.price_change_percentage_24h > 0 ? "zl_all_page_total_price_up_text" : "zl_add_currency_down_price"}>
                                        {coin_data[id].price.price_change_percentage_24h > 0 ? ptive_icon : ntive_icon}
                                        {(coin_data[id].price.price_change_percentage_24h).toFixed(2)}%
                                </span>
                            </div>
                            <div className="zl_wallet_chart_send_recive_btn">
                                <Button className="zl_wallet_chart_send_btn" onClick={handleToggle}>
                                    <svg width="15" height="15" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3.60609 3.60609L2.69695 4.51523C2.36222 4.84996 1.81951 4.84996 1.48477 4.51523C1.15004 4.18049 1.15004 3.63778 1.48477 3.30305L2.39391 2.39391L0 0H6V6L3.60609 3.60609Z" fill="#252F47" />
                                    </svg>
                                    Send
                                </Button>
                                <Button className="zl_wallet_chart_recive_btn" onClick={handleToggle}>
                                    <svg width="15" height="15" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3.60609 3.60609L2.69695 4.51523C2.36222 4.84996 1.81951 4.84996 1.48477 4.51523C1.15004 4.18049 1.15004 3.63778 1.48477 3.30305L2.39391 2.39391L0 0H6V6L3.60609 3.60609Z" fill="#252F47" />
                                    </svg>
                                    Receive
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className="zl_send_recive_content">
                        <div className="zl_send_recive_content_row">
                            <div className="zl_send_recive_content_column">
                                <div className="zl_send_recive_inner_content">
                                    <h3 className="zl_send_recive_heading">
                                        <svg width="15" height="15" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M3.60609 3.60609L2.69695 4.51523C2.36222 4.84996 1.81951 4.84996 1.48477 4.51523C1.15004 4.18049 1.15004 3.63778 1.48477 3.30305L2.39391 2.39391L0 0H6V6L3.60609 3.60609Z" fill="#53B9EA" />
                                        </svg>
                                        Send
                                    </h3>
                                    <div className="zl_send_qr_address">
                                        <FormControl
                                            placeholder="Insert address"
                                            onChange={(event) => {setSend_address(event.target.value)}}
                                        />
                                        <QRCode
                                            value={coin_data[id].data.addrStr}
                                            bgColor={"#3D476A"}
                                            fgColor={"#CAD3F2"}
                                            size={32}
                                            className="zl_dark_theme_qrcode"
                                        />
                                        <QRCode
                                            value={coin_data[id].data.addrStr}
                                            bgColor={"#EFF0F2"}
                                            fgColor={"#3D476A"}
                                            size={32}
                                            className="zl_light_theme_qrcode"
                                        />
                                    </div>
                                    <div className="zl_send_currency_input_content">
                                        <FormControl
                                            type="number"
                                            className="zl_send_currency_input"
                                            value={send_amount}
                                            onChange={(event) => {setSend_amount(event.target.value)}}
                                        />
                                        <div className="zl_send_currency_input_btns">
                                            <Button onClick={(event) => {setSend_amount(send_amount/4)}}>1/4</Button>
                                            <Button onClick={(event) => {setSend_amount(send_amount/2)}}>Half</Button>
                                            <Button onClick={(event) => {setSend_amount(coin_data[id].data.balance)}}>All</Button>
                                        </div>
                                    </div>
                                    <div className="zl_send_currency_text_type">
                                        <h3 className="zl_send_currency_text">{'$' + send_amount * coin_data[id].price.current_price.toFixed(4)}</h3>
                                        <h3 className="zl_send_currency_type">USD</h3>
                                    </div>
                                    <div className="zl_send_currency_btn_text">
                                        <Button className="zl_send_currency_btn" onClick={send_tx}>
                                            Send
                                        </Button>
                                        <div className="zl_send_currency_text">
                                            <p>Network Fee<span>{cuurency[id].fees + ' ' + cuurency[id].name}</span></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="zl_send_recive_content_column">
                                <div className="zl_send_recive_inner_content">
                                    <h3 className="zl_send_recive_heading zl_recive_heading">
                                        <svg width="15" height="15" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M3.60609 3.60609L2.69695 4.51523C2.36222 4.84996 1.81951 4.84996 1.48477 4.51523C1.15004 4.18049 1.15004 3.63778 1.48477 3.30305L2.39391 2.39391L0 0H6V6L3.60609 3.60609Z" fill="#53B9EA" />
                                        </svg>
                                        Receive
                                    </h3>
                                    <div className="zl_recive_address_content">
                                        <p className="zl_recive_address_heading">Address</p>
                                        <div className="zl_recive_copy_address_content">
                                            <Button onClick={() => navigator.clipboard.writeText(coin_data[id].data.addrStr)}>
                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M1.48116 0H12.5365C13.3244 0 13.9653 0.641 13.9653 1.42887V4.78252H12.661V1.42887C12.661 1.36022 12.6051 1.30435 12.5365 1.30435H1.48116C1.4125 1.30435 1.35663 1.36022 1.35663 1.42887V12.4842C1.35663 12.5529 1.4125 12.6087 1.48116 12.6087H4.73024V13.9131H1.48116C0.693287 13.9131 0.0522861 13.2721 0.0522861 12.4842V1.42887C0.0523291 0.641 0.693287 0 1.48116 0Z" fill="#CAD3F2" />
                                                    <path d="M7.46358 6.08691H18.5188C19.3068 6.08691 19.9478 6.72791 19.9478 7.51583V18.5711C19.9477 19.3591 19.3068 20.0001 18.5188 20.0001H7.46354C6.67562 20.0001 6.03463 19.3591 6.03463 18.5712V7.51583C6.03458 6.72791 6.67567 6.08691 7.46358 6.08691ZM7.46349 18.6957H18.5188C18.5875 18.6957 18.6434 18.6398 18.6434 18.5712V7.51583C18.6434 7.44713 18.5875 7.39126 18.5188 7.39126H7.46354C7.39484 7.39126 7.33897 7.44713 7.33897 7.51583V18.5712H7.33893C7.33893 18.6398 7.39484 18.6957 7.46349 18.6957Z" fill="#CAD3F2" />
                                                </svg>
                                            </Button>
                                            <p>{coin_data[id].data.addrStr}</p>
                                        </div>
                                        <div className="zl_recive_address_qr_code">
                                            <QRCode
                                                value={coin_data[id].data.addrStr}
                                                bgColor={"transparent"}
                                                fgColor={"#CAD3F2"}
                                                size={166}
                                                className="zl_dark_theme_qrcode"
                                            />
                                            <QRCode
                                                value={coin_data[id].data.addrStr}
                                                bgColor={"transparent"}
                                                fgColor={"#3D476A"}
                                                size={166}
                                                className="zl_light_theme_qrcode"
                                            />
                                        </div>
                                    </div>

                                </div>
                                <NotificationModal show_status={show_status} show={show} type={'tx'}/>
                                <Modal style={{'textAlign':'center'}} centered show={showp} onHide={() => setshowp(false)} backdrop={true} keyboard={false} >
                                    <Modal.Header closeButton>
                                    <Modal.Title>Enter Password</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div className="zl_login_input_content position-relative">
                                            <input type="password" className="zl_login_input" id={`pass_input`} onChange={(event) => {setwpass(event.target.value)}} placeholder='********'  />
                                        </div>
                                        <br />
                                        <div className="zl_login_btn">
                                            <Button id="pass_btn" onClick={ () => {send_tx();} } className="zl_send_currency_btn btn btn-primary"> {'Send Transaction' }</Button>
                                        </div>
                                    </Modal.Body>
                                </Modal>    
                            </div>
                        </div>
                    </div>
                    <div className="zl_transaction_list">
                        <h3 className="zl_transaction_list_main_heading">
                            Transaction
                        <Link to={'/history'}>See All</Link>
                        </h3>
                        <AllTransactionList id={id} coin_data={coin_data} limit={10} />
                    </div>
                </div>
            </>
        );
        

    }
}

export default connect(null, null)(MainComponent);
