import React, { useState } from "react";
import { connect } from "react-redux";
import HeadingModule from '../components/Layout/HeadingComponent/Heading';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Chart from "react-apexcharts";
import { Sparklines, SparklinesLine, SparklinesSpots } from 'react-sparklines';
import { cuurency } from "../helpers/currency_info";

const PortfolioModule = () => {
    // date picker

    

    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;

    const [coin_data, set_coin_data] = useState([{data:JSON.parse(localStorage.getItem('aya_data')),price:JSON.parse(localStorage.getItem('aya_price'))},{data:JSON.parse(localStorage.getItem('btc_data')),price:JSON.parse(localStorage.getItem('btc_price'))},{data:JSON.parse(localStorage.getItem('dash_data')),price:JSON.parse(localStorage.getItem('dash_price'))}]);

    const ntive_icon = <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.60609 2.39391L2.69695 1.48477C2.36222 1.15004 1.81951 1.15004 1.48477 1.48477C1.15004 1.81951 1.15004 2.36222 1.48477 2.69695L2.39391 3.60609L0 6H6V0L3.60609 2.39391Z" fill="#E3507A" /></svg>;
    const ptive_icon = <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.60609 3.60609L2.69695 4.51523C2.36222 4.84996 1.81951 4.84996 1.48477 4.51523C1.15004 4.18049 1.15004 3.63778 1.48477 3.30305L2.39391 2.39391L0 0H6V6L3.60609 3.60609Z" fill="#50E2C2" /></svg>;    
    
    // chart
    const options = {
        chart: {
            zoom: {
                enabled: true
            },
            stacked: false,
        },
        xaxis: {
            categories: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
            borderColor: '#999',
            yAxisIndex: 0,
            tickAmount: 6,
            label: {
                show: true,
                text: 'Support',
                style: {
                    colors: ['#fff'],
                    background: '#00E396'
                }
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            width: 2,
            curve: "smooth"
        },
        fill: {
            type: 'gradient',
            gradient: {
                // shadeIntensity: 1,
                opacityFrom: 0.7,
                opacityTo: 0.2,
                stops: [5, 100]
            }
        },
        // markers: {
        //     size: 0,
        //     style: 'hollow',
        // },
        colors: ['#F5A623', '#31AFD6', '#F84AC1'],
        legend: {
            position: 'top',
            horizontalAlign: 'left'
        },
        series: [
            {
                name: "BTC",
                data: [coin_data[1].price.sparkline_in_7d.price[24].toFixed(3),coin_data[1].price.sparkline_in_7d.price[48].toFixed(3),coin_data[1].price.sparkline_in_7d.price[72].toFixed(3),coin_data[1].price.sparkline_in_7d.price[96].toFixed(3),coin_data[1].price.sparkline_in_7d.price[120].toFixed(3),coin_data[1].price.sparkline_in_7d.price[144].toFixed(3),coin_data[1].price.sparkline_in_7d.price[166].toFixed(3)]
            },
            {
                name: 'AYA',
                data: [coin_data[0].price.sparkline_in_7d.price[24].toFixed(3),coin_data[0].price.sparkline_in_7d.price[48].toFixed(3),coin_data[0].price.sparkline_in_7d.price[72].toFixed(3),coin_data[0].price.sparkline_in_7d.price[96].toFixed(3),coin_data[0].price.sparkline_in_7d.price[120].toFixed(3),coin_data[0].price.sparkline_in_7d.price[144].toFixed(3),coin_data[0].price.sparkline_in_7d.price[166].toFixed(3)]
            },
            {
                name: 'DASH',
                data: [coin_data[2].price.sparkline_in_7d.price[24].toFixed(3),coin_data[2].price.sparkline_in_7d.price[48].toFixed(3),coin_data[2].price.sparkline_in_7d.price[72].toFixed(3),coin_data[2].price.sparkline_in_7d.price[96].toFixed(3),coin_data[2].price.sparkline_in_7d.price[120].toFixed(3),coin_data[2].price.sparkline_in_7d.price[144].toFixed(3),coin_data[2].price.sparkline_in_7d.price[166].toFixed(3)]
            }
        ]
    }
    return (
        <>
            <section className="zl_portfolio_page">
                <HeadingModule name={'Portfolio'} heading_description={'Current Portfolio.'} />
                <div className="zl_add_currency_content zl_protfolio_currency_content">
                    <h3 className="zl_bottom_content_heading">Wallets</h3>
                    <div className="zl_add_currency_row row">
                    {cuurency.map((item) => (
                        <div className="zl_add_currency_column col" key={'cur_col'+ item.id}>
                            <div className="zl_add_currency_inner_content">
                                <div className="zl_add_currency_icon_chart">
                                    <div style={{width: '70%'}}>
                                    <img width="28" height="28" src={item.icon} alt="logo"/>
                                    </div>
                                    <Sparklines data={[coin_data[item.id].price.sparkline_in_7d.price[24],coin_data[item.id].price.sparkline_in_7d.price[48],coin_data[item.id].price.sparkline_in_7d.price[72],coin_data[item.id].price.sparkline_in_7d.price[96],coin_data[item.id].price.sparkline_in_7d.price[120],coin_data[item.id].price.sparkline_in_7d.price[144],coin_data[item.id].price.sparkline_in_7d.price[166]]} margin={6} className="zl_add_currency_mini_chart">
                                        <SparklinesLine style={{ strokeWidth: 10, stroke: item.stroke, fill: "none", curve: "smooth" }} />
                                        <SparklinesSpots size={4}
                                            style={{ stroke: item.stroke, strokeWidth: 3, fill: "white" }} />
                                    </Sparklines>
                                </div>
                                <div className="zl_add_currency_price">
                                    <div className="zl_add_currency_left_price">
                                        <h3>{item.name}</h3>
                                        <p>{coin_data[item.id].data.balance}</p>
                                    </div>
                                    <div className="zl_add_currency_right_price">
                                        <span className={coin_data[item.id].price.price_change_percentage_24h > 0 ? "" : "zl_add_currency_down_price"}>
                                            {coin_data[item.id].price.price_change_percentage_24h > 0 ? ptive_icon : ntive_icon}
                                            {(coin_data[item.id].price.price_change_percentage_24h).toFixed(2)}%
                                        </span>
                                        <p>{ '$' + (coin_data[item.id].data.balance * coin_data[item.id].price.current_price).toFixed(4)} </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        ))}
                    </div>
                </div>
                <div className="zl_all_page_comman_content zl_protfolio_main_chart">
                    <div className="zl_dashboard_chart">
                        <Chart options={options} series={options.series} type="area" height={382} />
                    </div>
                    <div className="zl_all_page_comman_total_price">
                        <p className="zl_all_page_total_price_heading">Total Balance</p>
                        <h2 className="zl_all_page_total_price_text">

                        {'$' + ((coin_data[0].data.balance * coin_data[0].price.current_price) + (coin_data[1].data.balance * coin_data[1].price.current_price) + (coin_data[2].data.balance * coin_data[2].price.current_price)).toFixed(4)}
                        </h2>
                    </div>
                </div>
            </section>
        </>
    );
}

export default connect(null, null)(PortfolioModule);
