const assets_info = [{
    id:4,
    name: 'RAHA',
    full_name: 'RAHA',
    value: 'Raha Token',
    description:'Raha token is the official Toman Pegged token from the Aryacoin Team.',
    website:'aryacoin.io',
    verified:true,
    issuer:'AWw9goPncxmogCxe8vNWGG5spFYfLoqvL5',
    creationtxid:'f25afe0552c430dd5cea112e57a836f6bbad10b7cf4bc61d5466e5695fcd2892',
    logo: 'https://raw.githubusercontent.com/Aryacoin/assets/master/blockchains/aryacoin/assets/4/logo.png',
}];

export {assets_info};