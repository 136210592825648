import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import HeadingModule from '../components/Layout/HeadingComponent/Heading';
import { decrypt } from '../helpers/cryptoutils';
import { Modal , Button } from 'react-bootstrap';

// import { mapStateToProps } from './mappers';

const LogInModule = ({ navigation }) => {


    

    const inputField = [1,2,3,4,5,6,7,8,9,10,11,12];
    const [value, setValue] = useState('');

    const [wpass, setwpass] = useState(sessionStorage.getItem("sess_pass"));
    const [showp, setshowp] = useState(false);
        
    
    return (
        <section className="zl_securebackup_page">
            <HeadingModule name={'Secure Backup'} heading_description={'View and backup your 12 word passphrase, without these you will not be able to recover your wallet.'} />
            <div className="zl_SecureBackup_heading">
                <h3>Recovery words</h3>
            </div>
            <div className="zl_securebackup_row row">
                {inputField.map((inputValue,i) => (
                    <div className="zl_securebackup_col_3 col-lg-3 col-md-6" key={inputValue}>
                        <div className="zl_securebackup_input_content position-relative">
                            <p className="zl_securebackup_input_text">{inputValue}</p>
                            <input type="text"  readOnly={true} className="zl_securebackup_input" id={`input${inputValue}`} name={`input${inputValue}`} placeholder="****" defaultValue={value ? value.split(" ")[inputValue-1] : ""} />
                        </div>
                    </div>
                ))}
            </div>
            <div className="zl_securebackup_btn">
                {value === "" ? <Link to={'#'} onClick={() => { if(wpass === null) { setshowp(true); } else { setValue(decrypt(localStorage.getItem('mnemonic'),wpass)); setshowp(false);} }} className="mx-auto">Show</Link>
                    : <Link to={'#'} onClick={() => setValue("")} className="mx-auto">Hide</Link>}
            </div>

            <Modal style={{'textAlign':'center'}} centered show={showp} onHide={() => setshowp(false)} backdrop={true} keyboard={false} >
                <Modal.Header closeButton>
                <Modal.Title>Private Keys</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="zl_login_input_content position-relative">
                        <input type="password" className="zl_login_input" id={`pass_input`} onChange={(event) => {setwpass(event.target.value)}} placeholder='********'  />
                    </div>
                    <br />
                    <div className="zl_login_btn">
                        <Button id="pass_btn" onClick={ () => {setValue(decrypt(localStorage.getItem('mnemonic'),wpass)); setshowp(false);} } className="zl_send_currency_btn btn btn-primary"> {'Show Mnemonic' }</Button>
                    </div>
                </Modal.Body>
            </Modal>
        </section>
        
    );
}

export default connect(null, null)(LogInModule);