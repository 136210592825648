const cuurency = [{
    id:0,
    name: 'AYA',
    full_name: 'ARYACOIN',
    ratio: 0,
    updown: '0%',
    price: '$0',
    class: 'zl_add_aryacoin_currency',
    value: 'Aryacoin',
    icon: 'https://assets.coingecko.com/coins/images/8456/large/logo_(13).png?1558680682',
    stroke: '#A330FF',
    stroke2: '#E3507A',
    explorer: 'https://ayablockexplorer.com/',
    fees:0.00002
},
{
    id:1,
    name: 'BTC',
    full_name: 'BITCOIN',
    ratio: 0,
    updown: '0%',
    price: '$0',
    class: 'zl_add_bitcoin_currency',
    value: 'Bitcoin',
    icon: 'https://assets.coingecko.com/coins/images/1/large/bitcoin.png?1547033579',
    stroke: '#fec74f',
    stroke2: '#F7931A',
    explorer: 'https://bitcoinblockexplorer.com/',
    fees:0.00006
    
},
{
    id:2,
    name: 'DASH',
    full_name: 'DASH',
    ratio: 0,
    updown: '0%',
    price: '$0',
    class: 'zl_add_dash_currency',
    value: 'Dash',
    icon: 'https://assets.coingecko.com/coins/images/19/large/dash-logo.png?1548385930',
    stroke: '#309AFF',
    stroke2: '#309AFF',
    explorer: 'https://dashblockexplorer.com/',
    fees:0.00006
},];


export {cuurency};