import React from "react";
import { connect } from "react-redux";
import { Table } from 'react-bootstrap';
import { cuurency } from "../../../helpers/currency_info";

const AllTransactionListComponent = (props) => {
    // Generate from CoinData Transaction list

    const { id, coin_data, limit } = props;
    
    const transactionList = [];

    if(coin_data === null){

    }
    else{

        if(coin_data[id].data.txApperances === 0 ){

            // No transactions yet
        }
        else{
    
            coin_data[id].data.txs.map((txitem) =>{
    
                //Check if its in VIN , if yes check if its in VOUT, if yes , subtract
                var invin = false;
                var invout = false;
                var outamt = 0;
                var inamt = 0;
                txitem.vin.map((vin_item) =>{
    
                    if(vin_item.addresses.includes(coin_data[id].data.addrStr)){
                        invin = true;
                        outamt = outamt + parseFloat(vin_item.value);
                    }
    
                });
    
                txitem.vout.map((vout_item) =>{
    
                    if(vout_item.scriptPubKey.addresses.includes(coin_data[id].data.addrStr)){
                        invout = true;
                        inamt = inamt + parseFloat(vout_item.value);
                    }
    
                });
    
                var t_amt = inamt - outamt;
                if(t_amt > 0){
                    transactionList.push(
    
                        {
                            id: transactionList.length,
                            transactionType: cuurency[id].icon,
                            transactionName: cuurency[id].value,
                            transactionId: txitem.txid,
                            transactionValue: '+'+t_amt.toFixed(4),
                            transactionValueUpDown: 'zl_transaction_pluse',
                            transactionStatus: 'Completed',
                            transactionStatusUpDown: 'zl_transaction_completed',
                            transactionDate: new Date(txitem.time * 1000).toString()
                        }
            
            
                    );
                }
                else{
                    transactionList.push(
    
                        {
                            id: transactionList.length,
                            transactionType: cuurency[id].icon,
                            transactionName: cuurency[id].value,
                            transactionId: txitem.txid,
                            transactionValue: t_amt.toFixed(4),
                            transactionValueUpDown: 'zl_transaction_minas',
                            transactionStatus: 'Completed',
                            transactionStatusUpDown: 'zl_transaction_completed',
                            transactionDate: new Date(txitem.time * 1000).toString()
                        }
            
            
                    );
    
                }
                
    
            });
            
        }
    }
    
    
    //const [transaction, setTransaction] = useState(transactionList);

    if(transactionList.length === 0){
        return (
            <>
                <div className="overflow-auto">
                    <Table className="zl_transaction_list_table">
                        <thead>
                            <tr>
                                <th className="zl_transaction_list_table_heading">type</th>
                                <th className="zl_transaction_list_table_heading">name</th>
                                <th className="zl_transaction_list_table_heading">transaction id</th>
                                <th className="zl_transaction_list_table_heading">value</th>
                                <th className="zl_transaction_list_table_heading">status</th>
                                <th className="zl_transaction_list_table_heading">date</th>
                            </tr>
                        </thead>
                        <tbody>
                            
                        </tbody>
                    </Table>
                </div>
            </>
        );
    }
    else{
        return (
            <>
                <div className="overflow-auto">
                    <Table className="zl_transaction_list_table">
                        <thead>
                            <tr>
                                <th className="zl_transaction_list_table_heading">type</th>
                                <th className="zl_transaction_list_table_heading">name</th>
                                <th className="zl_transaction_list_table_heading">transaction id</th>
                                <th className="zl_transaction_list_table_heading">value</th>
                                <th className="zl_transaction_list_table_heading">status</th>
                                <th className="zl_transaction_list_table_heading">date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {transactionList.map((transactionListData, i) => (
                                <tr key={transactionListData.id}>
                                    <td className="zl_transaction_list_type">
                                        <img src={transactionListData.transactionType} alt="transaction-icon" />
                                    </td>
                                    <td className="zl_transaction_list_name">{transactionListData.transactionName}</td>
                                    <td className="zl_transaction_list_id"> <a href={cuurency[id].explorer + 'tx/' + transactionListData.transactionId } target="_blank" rel="noopener noreferrer">{transactionListData.transactionId}</a></td>
                                    <td className={`${transactionListData.transactionValueUpDown} zl_transaction_list_value`}>{transactionListData.transactionValue}</td>
                                    <td className={`${transactionListData.transactionStatusUpDown} zl_transaction_list_status`}>{transactionListData.transactionStatus}</td>
                                    <td className="zl_transaction_list_date">{transactionListData.transactionDate}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            </>
        );
    }
    
}

export default connect(null, null)(AllTransactionListComponent);
