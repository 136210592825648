import React , { useState } from "react";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { Modal , Button } from 'react-bootstrap';
import HeadingModule from '../components/Layout/HeadingComponent/Heading';
import {verify_mnemonic} from '../helpers/cryptoutils'
import {gen_ayakey} from '../helpers/ayautils'
import {gen_btckey} from '../helpers/btcutils'
import {gen_dashkey} from '../helpers/dashutils'
import { getprice, get_address_info } from '../helpers/cryptoutils'
import { cuurency } from "../helpers/currency_info";
import { get_omni_data, get_omni_balance } from '../helpers/ayautils';
import { encrypt } from '../helpers/cryptoutils';

const RestoreWalletModule = () => {


    
    const inputField = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

    const [show, setShow] = useState(false);
    const [show_status, setShow_status] = useState('Recovering Keys');
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [wpass, setwpass] = useState(sessionStorage.getItem("sess_pass"));
    const [showp, setshowp] = useState(false);
        

    const get_coin_data = async() => { 

        //console.log('Running Coin_get_data');
        
        localStorage.setItem('last_update', new Date().getTime());
        localStorage.setItem('f_update','false');

        const price_data_ = await getprice();
        console.log(price_data_);

        const c_data = [];
        await Promise.all(cuurency.map( async(item) => {
            c_data[item.id] = await get_address_info(item.name.toLowerCase());
            localStorage.setItem(item.name.toLowerCase() + '_data', JSON.stringify(c_data[item.id])) ;
            localStorage.setItem(item.name.toLowerCase() + '_price', JSON.stringify(price_data_[item.id]));
        }));

        console.log('CointData:' + c_data);
        console.log('PRiceData:' + price_data_);

        const _bal = await get_omni_balance(localStorage.getItem("aya_address"));
        const _data = await get_omni_data(localStorage.getItem("aya_address"));

        localStorage.setItem('aya_omni_balance', JSON.stringify(_bal)); 
        localStorage.setItem('aya_omni_data', JSON.stringify(_data)); 

        //set_coin_data([{data:JSON.parse(localStorage.getItem('aya_data')),price:JSON.parse(localStorage.getItem('aya_price'))},{data:JSON.parse(localStorage.getItem('btc_data')),price:JSON.parse(localStorage.getItem('btc_price'))},{data:JSON.parse(localStorage.getItem('dash_data')),price:JSON.parse(localStorage.getItem('dash_price'))}]); 
            
        
    }

    
    
    const login = async () => {

        if(wpass !== null){
            
            setshowp(false);
            handleShow();
            const mn = document.getElementById('input1').value.toLowerCase().replace( /[^a-z]/g, '' )+ " " + document.getElementById('input2').value.toLowerCase().replace( /[^a-z]/g, '' )+ " " + document.getElementById('input3').value.toLowerCase().replace( /[^a-z]/g, '' )+ " " + document.getElementById('input4').value.toLowerCase().replace( /[^a-z]/g, '' )+ " " + document.getElementById('input5').value.toLowerCase().replace( /[^a-z]/g, '' )+ " " + document.getElementById('input6').value.toLowerCase().replace( /[^a-z]/g, '' )+ " " + document.getElementById('input7').value.toLowerCase().replace( /[^a-z]/g, '' )+ " " + document.getElementById('input8').value.toLowerCase().replace( /[^a-z]/g, '' )+ " " + document.getElementById('input9').value.toLowerCase().replace( /[^a-z]/g, '' )+ " " + document.getElementById('input10').value.toLowerCase().replace( /[^a-z]/g, '' )+ " " + document.getElementById('input11').value.toLowerCase().replace( /[^a-z]/g, '' )+ " " + document.getElementById('input12').value;
            if(verify_mnemonic(mn)){
                localStorage.clear();

                localStorage.setItem('mnemonic', encrypt(mn.toString(),wpass));
                const p_aya = gen_ayakey(mn);
                const p_btc = gen_btckey(mn);
                const p_dash = gen_dashkey(mn);
                sessionStorage.setItem("sess_pass", wpass);
                localStorage.setItem('aya_key', encrypt(p_aya.toString(),wpass));
                localStorage.setItem('btc_key', encrypt(p_btc.toString(),wpass));
                localStorage.setItem('dash_key', encrypt(p_dash.toString(),wpass));
                setShow_status('Gathering Data');
                await get_coin_data();
                //setShow_status('Redirecting');
                //return history.push("/securebackup");
                window.location.replace("/securebackup");
            }
            else{
                setShow_status('Invalid Mnemonic');
            }
            
        }
        else{
            setshowp(true);
        }
        
        
    };

    return (
        <>
            <section className="zl_restore_wallet_page">
                <HeadingModule name={'Restore Wallet'} heading_description={'Restore wallet using the 12 word passphrase.'} />
                <div className="zl_restore_wallet_input_content">
                    <div className="zl_securebackup_row row">
                        {inputField.map((inputValue, i) => (
                            <div className="zl_securebackup_col_3 col-lg-3 col-md-6" key={i}>
                                <div className="zl_securebackup_input_content position-relative">
                                    <p className="zl_securebackup_input_text">{inputValue}</p>
                                    <input type="text" className="zl_securebackup_input" id={`input${inputValue}`} name={`input${inputValue}`} placeholder="________" />
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="zl_securebackup_btn">
                        <Link to={'#'} onClick={login} className="mx-auto">Restore</Link>
                    </div>
                    <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} >
                        <Modal.Header>
                        <Modal.Title>{show_status}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                        {show_status}
                        </Modal.Body>
                    </Modal>
                    <Modal style={{'textAlign':'center'}} centered show={showp} onHide={() => setshowp(false)} backdrop={true} keyboard={false} >
                        <Modal.Header closeButton>
                        <Modal.Title>Enter Password</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="zl_login_input_content position-relative">
                                <input type="password" className="zl_login_input" id={`pass_input`} onChange={(event) => {setwpass(event.target.value)}} placeholder='********'  />
                            </div>
                            <br />
                            <div className="zl_login_btn">
                                <Button id="pass_btn" onClick={ () => {login();} } className="zl_send_currency_btn btn btn-primary"> {'Restore Wallet' }</Button>
                            </div>
                        </Modal.Body>
                    </Modal>  
                </div>
            </section>
        </>
    );
}

export default connect(null, null)(RestoreWalletModule);
