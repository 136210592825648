import React from "react";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import { Sparklines, SparklinesLine, SparklinesSpots } from 'react-sparklines';

// import { mapStateToProps } from './mappers';

const HeadingModule = (props) => {

    return (
        <>
            <div className="zl_all_page_heading_section">
                <div className="zl_all_page_heading">
                    <h2>{props.name}</h2>
                    <p>{props.heading_description}</p>
                </div>
                <div className="zl_all_page_notify_logout_btn">
                    <Link to={'/login'} className="zl_all_page_logout_btn">Log Out</Link>
                </div>
            </div>
        </>
    );
}

export default connect(null, null)(HeadingModule);
